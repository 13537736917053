import React from "react";
import {
  ContainerSection,
  ItemSection,
  MainTitle,
  Paragraph24,
  ContainerItems,
  ItemWrapper,
  Button,
} from "../../assets/styles/StylesGeneric.styled";
import "./../../assets/styles/StylesGeneric.css";
import { useNavigate } from "react-router-dom";

import TagManager from 'react-gtm-module';

 
const eventoGTM = (event, response) => {
  const tagManagerArgs = {
    dataLayer: {
      event,
      response,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};


const FrontSectionTwo = () => {
  const navigate = useNavigate();
  return (
    <>
    <ContainerSection>
     { eventoGTM('LpAfores')}
     <ContainerItems
          m="auto"
          pM="0"
          fdM900="column-reverse"
          mw="unset"
          bgcolor="#004C97"
          fontFamily="SF UI"
        >

          <ItemWrapper w="40%" jc="center" minHeightM=" " jc900="center">
            <ItemSection
              w="100%"
              mw="420px"
              m="0 30px"
              m900="30px 0"
              padM=" 0rem 1rem 0 1.3rem !important"
            >
              <MainTitle> En Principal <span className="spanr">®</span>te apoyamos a diseñar un plan de retiro a la medida </MainTitle>
              <Paragraph24 hMob="auto" mb320Mob="40px" mb="30px" mw="480px">
              ¡Nunca la Afore había estado tan cerca de ti! 
              Ahora ya puedes llevar a cabo tu proceso de cambio a <b>Principal Afore</b>  sin salir de casa.
                {/* Ahorra para tu retiro. ¡Nunca la Afore había estado tan cerca de
                ti!  <br/> <br/> Sin necesidad de salir de casa, ahora es posible llevar a

                cabo tu proceso de cambio con tus amigos de principal. */}

              </Paragraph24>
              <Button
              clr="#0061A0"
              bgck="#fff"
              minWidth="0px"
              w="204px"
              h="48px"
              pad="6.5px 24px"
              wMob="100%"
              maxwMob="200px"
              mt="0.5rem"
              fontw="700"
              mMob900="30px auto 0px"
              mbMob=" "
              HoverbackgroundColor="#F6F6F8"
              hColor="#004887"
              onClick={(e) => {
                e.preventDefault();
                navigate("/descarga-app-bis");
                 eventoGTM('CTA_Quiero_cambiarme')
              }}
            >
             ¡Quiero cambiarme!
            </Button>

            </ItemSection>
          </ItemWrapper>
          <ItemWrapper overflow="hidden" w="40%" maxH900="55vh" dis=" " dis900=" " dis800="none">

            <img
              className="img-front"
              src="/rocket/afores/static/assets/images/frontImage.png"
              alt=""
              onClick={(e) => {
                e.preventDefault();
                navigate("/descarga-app-bis");
                 eventoGTM('CTA_Quiero_cambiarme')
              }}
            />

          </ItemWrapper>
        </ContainerItems>
      </ContainerSection>
    </>
  );
};

export default FrontSectionTwo;