import {
  ContainerSection,
  Button,
  ContainerItems,
  ItemWrapper,
  ItemRow,
  CustomParagraph,
  TitlePerfiladorAfore,
  ItemCol,
  ItemWrapperDownloadSectionV2,
  ItemWrapperaForeSearchSection,
} from "../../assets/styles/StylesGeneric.styled";
import googlePlay from "../../assets/images/GooglePlay.png";
import appStore from "../../assets/images/AppStore.png";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";

const eventoGTM = (event, response) => {
  const tagManagerArgs = {
    dataLayer: {
      event,
      response,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

const SearchAforeCard = () => {
  const navigate = useNavigate();

  return (
    <ContainerSection>
      <ContainerItems
        mw="100%"
        m="auto"
        disMob="column-reverse"
        fdir="row"
        fdM="column"
        ai="unset"
      >
        <ItemWrapper w="50%" bgcolor="#002855" height=" " jc="flex-end">
          <div className="py-md-4 py-3 pe-md-5 me-md-5">
            <div className="me-md-5 pe-md-1">
              <ContainerItems mw="34rem" fw="wrap" alc="center" pad="0 25px">
                <ItemRow m="15px 0" txal="left" jc="start">
                  <CustomParagraph
                    fontw="700"
                    tal="left"
                    fs="30px"
                    talMob="center"
                  >
                    ¿Cómo saber si mi Afore está en Principal
                    <span className="spanr2">®</span>?
                  </CustomParagraph>
                </ItemRow>
                <ItemRow m="15px 0" jc="start">
                  <CustomParagraph tal="leftr" fs="24px" talMob="center">
                    Solo da clic en el botón y descubre si tu Afore se encuentra
                    con nosotros.
                  </CustomParagraph>
                </ItemRow>
                <ItemRow m="15px 0" jc="start">
                  <Button
                    clr="#0061A0"
                    bgck="#fff"
                    minWidth="0px"
                    w="224px"
                    h="48px"
                    pad="6.5px 24px"
                    wMob="100%"
                    maxwMob="200px"
                    mt="0.5rem"
                    mMob900="30px auto 0px"
                    mbMob=" "
                    ff="eliotprobold"
                    fontw="500"
                    HoverbackgroundColor="#F6F6F8"
                    hColor="#004887"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/busqueda-afore");
                      eventoGTM("CTA_Buscar-Mi-Afore");
                    }}
                  >
                    Buscar mi Afore
                  </Button>
                </ItemRow>
              </ContainerItems>
            </div>
          </div>
        </ItemWrapper>
        <ItemWrapperaForeSearchSection
          w="50%"
          height=" "
          jc="flex-start"
          minHeightM="300px"
        />
      </ContainerItems>
    </ContainerSection>
  );
};

const DownloadPrincipal = () => {
  const stepsToTransferAfore = [
    {
      name: (
        <span>
          {" "}
          Ingresa los datos faltantes que aparecen dentro de la aplicación de{" "}
          <b className="strongpin">
            Principal<span className="spanr3">®</span> México.
          </b>
        </span>
      ),
      image: "/rocket/afores/static/assets/icons/DataIcon.svg",
    },
    {
      name: "Podrás verificar tu cuenta con Principal Afore.",
      image: "/rocket/afores/static/assets/icons/VerifyIcon.svg",
    },
    {
      name: "Por último, realiza la firma de contrato de tu traspaso y ¡Listo!",
      image: "/rocket/afores/static/assets/icons/SignIcon.svg",
    },
  ];
  return (
    <ItemWrapper
      pad="7% 5% 7% 5%"
      pad800="5% 10%"
      pad500="10%"
      bgcolor="#F0FBFE"
    >
      <div className="">
        <div className="">
          <div className="">
            <TitlePerfiladorAfore
              w="100%"
              m768="0 0 1.6rem"
              wM1milup=""
              wM9680=""
              fons="38px"
              mFontSize="34px"
              mLineHeight="40px"
              fontf="eliotproBold"
              textAlign="start"
            >
              ¡Descarga nuestra aplicación Principal<sup>®</sup> México y
              realiza tu traspaso a Principal Afore!
            </TitlePerfiladorAfore>
          </div>
          <ItemRow m="50px 0">
            <ItemCol
              w="30%"
              w1500="25%"
              w900="30%"
              w800="45%"
              m="0px"
              w400="100%"
            >
              <div className="app-btn-container text-center">
                <a
                  target="_blank"
                  href="https://bit.ly/3dKTc93"
                  rel="noreferrer"
                  onClick={() => eventoGTM("Descargar_Google_Play_Lp")}
                  className="w-100 text-center"
                >
                  <img
                    src={googlePlay}
                    alt="Principal App on Playstore"
                    style={{ maxWidth: "135px" }}
                  />
                </a>
              </div>
            </ItemCol>
            <ItemCol
              w="18%"
              w1500="25%"
              w900="30%"
              w800="45%"
              m="0px"
              w400="100%"
              mt400="0.5rem"
            >
              <div className="app-btn-container text-center">
                <a
                  target="_blank"
                  href="https://apple.co/3wsSSlN"
                  rel="noreferrer"
                  onClick={() => eventoGTM("Descargar_AppStore_Lp")}
                  className="w-100 text-md-end text-center"
                >
                  <img
                    src={appStore}
                    alt="Principal App on App Store"
                    style={{ maxWidth: "122px" }}
                  />
                </a>
              </div>
            </ItemCol>
          </ItemRow>
          <div className="">
            <CustomParagraph
              clr="#333333"
              w="80%"
              wMob375="90%"
              ml7="5%"
              fs=""
              ff="eliotpro"
              wMob=""
              mbb375=""
              marginBottom="30px"
            >
              Te compartimos los pasos para realizar tu traspaso a Principal
              <span className="spanr3">®</span>, solo deberás tener a la mano tu
              INE y CURP.
            </CustomParagraph>
          </div>
        </div>
        <div className="">
          <div className="">
            {stepsToTransferAfore.map((step, idx) => {
              const { name, image } = step;
              return (
                <div key={idx} className="StepsContainer">
                  <div className="CardsStepsOne">
                    <img src={image} alt="" style={{ width: "48px" }} />
                  </div>
                  <div className="CardsStepstwo">
                    <CustomParagraph clr="#333333" fs="20px" ff="eliotpro">
                      {name}
                    </CustomParagraph>
                  </div>
                </div>
              );
            })}
          </div>

          {/*<div className="row  pt-5  d-flex justify-content-center m-0 align-items-center">
              <div className="col-md-3 col-6 text-center mb-md-0 pt-1">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.principal.fondosinversion"
                  rel="noreferrer"
                  className="w-100 text-center"
                >
                  <img
                    src={googlePlay}
                    alt="Principal App on Playstore"
                     className="img-mobileGoogle"
                  />
                </a>

              </div>
              <div className="col-md-3 col-6 text-center pt-1">
                <a
                  target="_blank"
                  href="https://apps.apple.com/mx/app/principal-m%C3%A9xico/id1491050507"
                  rel="noreferrer"
                  className="w-100 text-md-end text-center"
                >
                  <img
                    src={appStore }
                    alt="Principal App on App Store"
                    className="img-mobileAppStore"
                  />
                </a>
              </div>
            </div>*/}
        </div>
      </div>
    </ItemWrapper>
  );
};

const DownloadPrincipalCard = () => (
  <ContainerSection>
    <ContainerItems
      mw="100%"
      m="auto"
      disMob="column-reverse"
      fdir="row"
      fdM="column-reverse"
      ai="unset"
    >
      <ItemWrapperDownloadSectionV2
        w="50%"
        w800="100%"
        jc="flex-start"
        minHeightM="400px"
        overflow="hidden"
        dis=" "
        dis900=" "
        height=" "
        DMob="none"
      />

      <ItemWrapper w="50%" bgcolor="#F0FBFE" height=" " jc="flex-end">
        <DownloadPrincipal />
      </ItemWrapper>
    </ContainerItems>
  </ContainerSection>
);

const SectionTwoV2Bis = () => {
  return (
    <div className="d-md-block">
      {/* <SearchAforeCard /> */}
      <DownloadPrincipalCard />
    </div>
  );
};

export default SectionTwoV2Bis;
