import "./../../assets/styles/flipcard.css";
import cn from "classnames";
import { ItemRow, SimpleLink } from "../../assets/styles/StylesGeneric.styled";

function FlipCardMobile({ card, id, showBack }) {
  // const refCard = useRef(null);
  // function handleClick() {
  //   setShowBack((prev) => (prev === id ? 0 : id));
  //   refCard.current.classList.toggle("flip");
  // }
  // function handleClickOut() {
  //   setShowBack(0);
  // }
  const valid = card.id === showBack;
  return (
    <div
      className="flip-card"
      // onClick={handleClick}
      // onBlur={handleClickOut}
    >
      <div
        className={cn("flip-card-inner", {
          valid,
          "hover-trigger": card.variant === "hover",
        })}
      >
        <div className="card front flip-card-front">
          <div className="card-body d-flex justify-content-center align-items-center">
            <ItemRow>
              <img
                className="card-text fs-1 fw-bold"
                src={card.src}
                alt=""
              ></img>
            </ItemRow>
            <ItemRow>
              <p className="card-text-front  fw-bold"> {card.frontText}</p>
            </ItemRow>
            <ItemRow>
              {/* 
              <img
                className="card-text fs-1 fw-bold"
                src="https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/plusIcon.7ffa752635c721ffe8b9a82093761d4f.svg"
                alt=""
              ></img> */}

              <SimpleLink
                textDecStyle="dashed"
                fontSize="18px"
                fontWeight="700"
              >
                Ver más
              </SimpleLink>
            </ItemRow>
          </div>
        </div>
        <div className="card back flip-card-back">
          <div className="card-body-back d-flex justify-content-center ">
            <ItemRow>
              <p className="card-title-back  ">{card.backTitle}</p>
            </ItemRow>
            <ItemRow>
              <div className="card-text-back  ">{card.backText1}</div>
            </ItemRow>
            <ItemRow>
              <div className="card-text-back  ">{card.backText2}</div>
            </ItemRow>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlipCardMobile;
