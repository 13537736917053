import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const eliotpro = {
  fontFamily: "eliotPro",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/FSElliotPro-Regular.98713fb82821a07ef26e.ttf") format('truetype')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF UTF-8",
};

const eliotprobold = {
  fontFamily: "eliotProBold",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/FSElliotPro-Bold.c845dd58dd2dbbf5f2bb.ttf") format('truetype')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF UTF-8",
};

const eliotprolight = {
  fontFamily: "eliotProLight",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/FSElliotPro-Light.4d065b3ce535bbc03c04.ttf") format('truetype')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF UTF-8",
};

const eliotproheavy = {
  fontFamily: "eliotProHeavy",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/FSElliotPro-Heavy.6b61ec4643672328003e.otf") format('truetype')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF UTF-8",
};

const mitheme = createTheme({
  palette: {
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "eliotPro",
      "eliotProBold",
      "eliotProLight",
      "eliotProHeavy",
    ].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [eliotpro, eliotprobold, eliotprolight, eliotproheavy],
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={mitheme}>
      <CssBaseline />
      <App/>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
