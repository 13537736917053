/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

// import PropTypes from 'prop-types'
import {
  TitlePerfiladorAfore,
  ContainerItems,
  Button,
  ItemRow,
  CustomParagraph,
  ItemWrapper,
  ItemSection,
  BoxModal,
  ModalTitle,
} from "../assets/styles/StylesGeneric.styled";
import Contacto from "../components/common/Contacto";
import ComponenteInput from "../components/common/Input/Input";
import { makeStyles, Modal } from "@material-ui/core";
import { Formulario } from "../components/common/Input/elementos/Formularios";
import lambdas from "../config/lambdas/lambdas";
import { useNavigate } from "react-router-dom";
import ScreenIcon from "../assets/icons/ScreenIcon.svg";
import closeIcon from "../assets/icons/closeIcon.svg";
import likeIcon from "../assets/icons/likeIcon.svg";
import { animateScroll as scroll } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    padding: "16px 18px",
  },
}));

function PerfiladorContacto() {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  function handleClose() {
    setOpen(false);
  }

  const handleSubmit = () => {
    if (
      nombre.campo !== "" &&
      nombre.valido === "true" &&
      apellidoPaterno.campo !== "" &&
      apellidoPaterno.valido === "true" &&
      apellidoMaterno.campo !== "" &&
      apellidoMaterno.valido === "true" &&
      telefono.campo !== "" &&
      telefono.valido === "true" &&
      correo.campo !== "" &&
      correo.valido === "true"
    ) {
      setOpen(true);
      const data = {
        nombre: nombre.campo,
        apellido_paterno: apellidoPaterno.campo,
        apellido_materno: apellidoMaterno.campo,
        celular: telefono.campo,
        email: correo.campo,
        id_session: sessionStorage.getItem("uuid_principal_user"),
      };
      // const token = lambdas
      //   .getAuth()
      //   .then((response) => {
      //     lambdas.crearCuenta(data, response.data.token).then((response) => {
      //       console.log("response", response);
      //       if (!response.data.Error) {
      //         const { existe_cliente } = response.data.Message;
      //         console.log("response buscar afore", response.data.Message);
      //       }
      //       scroll.scrollToTop();
      //     });
      //   })
      //   .catch((error) => {
      //     console.log(error.data);
      //   });
      const token = sessionStorage.getItem("r-token") || "";
      lambdas.crearCuenta(token).then((response) => {
        console.log("response", response);
        if (!response.data.Error) {
          const { existe_cliente } = response.data.Message;
          console.log("response buscar afore", response.data.Message);
        }
        scroll.scrollToTop();
      });
    } else {
      if (nombre.campo === "") {
        setNombre({ valido: "false" });
      }
      if (apellidoPaterno.campo === "") {
        setApellidoPaterno({ valido: "false" });
      }
      if (apellidoMaterno.campo === "") {
        setApellidoMaterno({ valido: "false" });
      }
      if (telefono.campo === "") {
        setTelefono({ valido: "false" });
      }
      if (correo.campo === "") {
        setCorreo({ valido: "false" });
      }
    }
  };

  const classes = useStyles();
  const ModalMessage = () => (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <BoxModal style={modalStyle} className={classes.paper}>
        <ItemRow w="100%" jc="flex-end">
          <img
            src="https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/closeIcon.7b7658068e2567e7de8875b0f4996625.svg"
            alt=""
            onClick={handleClose}
          />
        </ItemRow>
        <ItemRow w="100%">
          <img src="https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/likeIcon.7b783e6e5a8248aa850715c301b8004f.svg" />
        </ItemRow>
        <ItemRow w="100%">
          <ModalTitle>¡Excelente!</ModalTitle>
        </ItemRow>

        <ItemRow w="100%">
          <CustomParagraph fs="18px" clr="#333333">
            Cada vez estás más cerca de realizar tu cambio de Afore a Principal.
          </CustomParagraph>
        </ItemRow>
        <ItemRow w="100%">
          <CustomParagraph fs="18px" clr="#333333">
            Ya tenemos tus datos y serás contactado por un asesor lo antes
            posible.
          </CustomParagraph>
        </ItemRow>
        <ItemRow w="100%">
          <Button
            type="button"
            onClick={() => {
              handleClose();
              navigate("/");
            }}
            als="center"
            mb="1rem 0rem auto"
          >
            Terminar
          </Button>
        </ItemRow>
      </BoxModal>
    </Modal>
  );

  const [nombre, setNombre] = useState({ campo: "", valido: null });
  const [apellidoPaterno, setApellidoPaterno] = useState({
    campo: "",
    valido: null,
  });
  const [apellidoMaterno, setApellidoMaterno] = useState({
    campo: "",
    valido: null,
  });
  const [telefono, setTelefono] = useState({ campo: "", valido: null });
  const [correo, setCorreo] = useState({ campo: "", valido: null });

  const expresiones = {
    // usuario: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    // password: /^.{4,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{10}$/, // 10 numeros.
    curp: /^[A-Z]{4}\d{1,6}[A-Z]{6}\d{2}$/,
  };

  return (
    <>
      <ItemWrapper dis="block" bgcolor="#F0FBFE">
        <div className="m-0 row d-flex justify-content-center">
          <div className="col-lg-6 px-md-0 px-3 text-center mt-5 pt-3">
            <img
              src="https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/ScreenIcon.13098d7bc02dc5c7dbb15b3fd730e4a7.svg"
              alt=""
            />
            <TitlePerfiladorAfore
              m="3rem 0"
              fontf="SF UI"
              mFontSize="30px"
              mLineHeight="35.8px"
            >
              Existe una baja probabilidad que tengas <br /> expediente digital.
            </TitlePerfiladorAfore>
          </div>
          <div className="col-lg-7 px-md-5 px-4">
            <ItemRow>
              <CustomParagraph
                clr="#333333"
                fs="18px"
                tal="center"
                marginBottom="5%"
                ff="SF UI"
              >
                Un expediente digital es un registro del trabajador en el
                sistema de ahorro para el retiro, sirve para realizar de forma
                más ágil los trámites relacionados con tu cuenta de Afore.
              </CustomParagraph>
              <CustomParagraph
                clr="#333333"
                fs="28px"
                tal="center"
                fontw="700"
                marginBottom="5%"
                fsMob="18px"
                ff="SF UI"
              >
                Para obtener tu expediente necesitas ayuda de un asesor.
              </CustomParagraph>
              <CustomParagraph
                clr="#333333"
                fs="18px"
                tal="center"
                marginBottom="5%"
                ff="SF UI"
              >
                Registra los siguientes datos para ser contactado por un asesor
                lo antes posible.
              </CustomParagraph>
            </ItemRow>
            <div className="row px-md-0 px-2">
              <ContainerItems ai="start" pad="0rem 0rem 2rem">
                <ItemSection w="100%" mw="450px" wrap="wrap">
                  <Formulario style={{ gap: "3rem" }} gtc="1fr">
                    <div>
                      <ComponenteInput
                        estado={nombre}
                        cambiarEstado={setNombre}
                        tipo="text"
                        placeholder=""
                        name="nombre"
                        label="Nombre"
                        padLabel="0px"
                        disp="flex"
                        alignIt="flex-end"
                        onOnlyTextChange
                        expresionRegular={expresiones.nombre}
                        leyendaError="Nombre inválido"
                      />

                      <ComponenteInput
                        estado={apellidoPaterno}
                        cambiarEstado={setApellidoPaterno}
                        tipo="text"
                        placeholder=""
                        onOnlyTextChange
                        label="Apellido Paterno"
                        padLabel="0px"
                        disp="flex"
                        alignIt="flex-end"
                        name="apellido-paterno"
                        leyendaError="Apellido inválido"
                        expresionRegular={expresiones.nombre}
                      />

                      <ComponenteInput
                        id="formatted-numberformat-input"
                        estado={apellidoMaterno}
                        cambiarEstado={setApellidoMaterno}
                        onOnlyTextChange
                        tipo="text"
                        placeholder=""
                        label="Apellido Materno"
                        padLabel="0px"
                        disp="flex"
                        alignIt="flex-end"
                        name="apelido-materno"
                        expresionRegular={expresiones.nombre}
                        leyendaError="Apellido inválido"
                      />

                      <ComponenteInput
                        estado={telefono}
                        cambiarEstado={setTelefono}
                        tipo="text"
                        placeholder=""
                        name="nombre"
                        label="Teléfono Celular"
                        padLabel="0px"
                        disp="flex"
                        alignIt="flex-end"
                        onTelephoneChange
                        expresionRegular={expresiones.telefono}
                        leyendaError="Teléfono celular inválido"
                      />

                      <ComponenteInput
                        estado={correo}
                        cambiarEstado={setCorreo}
                        tipo="text"
                        onTextlimitChange
                        placeholder=""
                        name="nombre"
                        label="Correo electrónico"
                        padLabel="0px"
                        disp="flex"
                        alignIt="flex-end"
                        expresionRegular={expresiones.correo}
                        leyendaError="Correo inválido"
                      />
                    </div>
                  </Formulario>
                </ItemSection>
              </ContainerItems>
              <div className="text-center mt-md-2 mb-md-5 mb-3 pt-2 pb-2">
                <Button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  als="center"
                  mb="1rem 0rem auto"
                >
                  Contactarme
                </Button>
              </div>
              <ContainerItems bgck="#E3F5FD" br="16px" m="auto 0">
                {open ? <ModalMessage /> : null}
              </ContainerItems>
            </div>
          </div>
        </div>
      </ItemWrapper>
      {/*
      <Contacto />
      */}
    </>
  );
}

// LpAfore.propTypes = {}

export default PerfiladorContacto;
