import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "@material-ui/core/TextField";

const colores = {
  borde: "#0076CF",
  error: "#F76800",
  exito: "#0076CF",
};

const Formulario = styled.form`
  display: grid;
  grid-template-columns: ${(props) => props.gtc || "1fr 1fr"};

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

const Label = styled.label`
  font-family: "SF UI";
  font-size: ${(props) => props.fontSize || "16px"};
  display: ${(props) => props.disp || "block"};
  font-weight: ${(props) => props.fontwLabel || "400"};
  padding: ${(props) => props.padLabel || "10px 0px"};
  min-height: ${(props) => props.hLabel || "40px"};
  cursor: pointer;
  height: 30px;
  align-items: ${(props) => props.alignIt || ""};
  color: ${(props) => props.clrLabel || " "};
  transform: matrix(1, 0, 0, 1, 0, 0);
`;

const GrupoInput = styled.div`
  position: relative;
  z-index: 20;
`;

const Input = styled(TextField)`
  height: 48.92px;
  width: ${(props) => props.w || "100%"};
  min-width: 110px;
  padding: 1rem 2rem 1rem 1rem;
  font-weight: 400;
  box-sizing: border-box;
  font-size: 16px;
  font-family: "eliotpro";
  background: #ffffff;
  border: ${(props) => props.border || " 1px solid #333333"};
  border-radius: 4px;
  color: #333333;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin-bottom: ${(props) => props.mbtn || " "};
  &:hover{
    border: 1.5px solid #004887;
    
  }
  &:focus {
    border: 3px solid ${colores.borde};
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
  }

  ${(props) =>
    props.valido === "true" &&
    css`
      border: 1px solid #616267;
      box-shadow: 0px 0px 12px #002e6d;
    `}

  ${(props) =>
    props.valido === "false" &&
    css`
    border: 2px solid #C00000;!important;
    `}
	.MuiInput-underline:before {
    border-bottom: none;
  }
  && .MuiInput-underline:hover:before {
    border-bottom: none;
  }
  
  /* focused */
  .MuiInput-underline:after {
    border-bottom: none;
  }
  [type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .MuiSelect-icon {
    top: calc(50% - 12px);
    color: rgba(0, 0, 0, 0.54);
    right: -30px !important;
    position: absolute;    
    pointer-events: none;
}
  @media (max-width: 800px) {
   
    width: ${(props) => props.wM || " "};
    max-width: ${(props) => props.maxw || " "};
    min-width:  ${(props) => props.minw || "unset"};
    }
    @media (max-width: 350px) {
   
      width: ${(props) => props.wM300 || " "};
      max-width: ${(props) => props.maxw300 || " "};
      
    }  
  }
`;

const LeyendaError = styled.p`
  font-size: 12px;
  margin-bottom: 0;
  color: #c00000;
  display: none;

  ${(props) =>
    props.valido === "true" &&
    css`
      display: none;
    `}

  ${(props) =>
    props.valido === "false" &&
    css`
      display: block;
    `}
`;

const IconoValidacion = styled(FontAwesomeIcon)`
  position: absolute;
  right: 10px;
  bottom: 17px;
  z-index: 100;
  font-size: 16px;
  opacity: 0;

  ${(props) =>
    props.valido === "false" &&
    css`
      opacity: 1;
      color: ${colores.error};
    `}

  ${(props) =>
    props.valido === "true" &&
    css`
      opacity: 1;
      color: ${colores.exito};
    `}
`;
const AsteriskValidacion = styled.p`
  font-size: ${(props) => props.fz || "14px"};
  margin-bottom: ${(props) => props.mb || "10px"};
  margin-left: ${(props) => props.ml || "4px"};
  position: ${(props) => props.ps || " "};
  top: ${(props) => props.top || " "};
  left: ${(props) => props.left || " "};
  color: #c00000;
  ${(props) =>
    props.valido === "false" &&
    css`
      color: #c00000;
    `}
`;

const ContenedorTerminos = styled.div`
  grid-column: span 2;

  input {
    margin-right: 10px;
  }

  @media (max-width: 800px) {
    grid-column: span 1;
  }
`;

const ContenedorBotonCentrado = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;

  @media (max-width: 800px) {
    grid-column: span 1;
  }
`;

const Boton = styled.button`
  height: 45px;
  line-height: 45px;
  width: 30%;
  background: #000;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.1s ease all;

  &:hover {
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 1);
  }
`;

const MensajeExito = styled.p`
  font-size: 14px;
  color: ${colores.exito};
`;

const MensajeError = styled.div`
  height: 45px;
  line-height: 45px;
  background: #f66060;
  padding: 0px 15px;
  border-radius: 3px;
  grid-column: span 2;
  p {
    margin: 0;
  }
  b {
    margin-left: 10px;
  }
`;

export {
  Formulario,
  Label,
  GrupoInput,
  Input,
  LeyendaError,
  IconoValidacion,
  ContenedorTerminos,
  ContenedorBotonCentrado,
  Boton,
  MensajeExito,
  MensajeError,
  AsteriskValidacion,
};
