import React from "react";

import {
  TitlePerfiladorAfore,
  ItemRow,
  CustomParagraph,
  Title,
  ItemWrapper,
  ItemCol,
  ContainerButton,
  Btn,
} from "../assets/styles/StylesGeneric.styled";

import googlePlay from "../assets/images/GooglePlay.png";
import appStore from "../assets/images/AppStore.png";

import TagManager from "react-gtm-module";

function PerfiladorCuentaV2() {
  const [isAndroid, setIsAndroid] = React.useState(true);
  //const imgWidth = "100%";
  const eventoGTM = (event, response) => {
    const tagManagerArgs = {
      dataLayer: {
        event,
        response,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };
  const stepsToTransferAfore = [
    {
      name: "Tener CURP e INE a la mano.",
      image: "/rocket/afores/static/assets/icons/IneIcon.svg",
    },

    {
      name: "Capturar la información necesaria para el trámite.",
      image: "/rocket/afores/static/assets/icons/DataIcon.svg",
    },
    {
      name: "Verifica tus datos de contacto.",
      image: "/rocket/afores/static/assets/icons/VerifyIcon.svg",
    },
    {
      name: "Video de Conoce a Principal.",
      image: "/rocket/afores/static/assets/icons/VideoIcon.svg",
    },
    {
      name: "Verificar tu identidad a través de la cámara de tu celular.",
      image: "/rocket/afores/static/assets/icons/PhotoIcon.svg",
    },
    {
      name: "Por último, realiza la firma de contrato de tu traspaso.",
      image: "/rocket/afores/static/assets/icons/SignIcon.svg",
    },
    {
      name: "Recibe en tu correo la confirmación y ¡Listo!",
      image: "/rocket/afores/static/assets/icons/EmailIcon.svg",
    },
  ];

  return (
    <>
      <ItemWrapper dis="block" bgcolor="#F0FBFE">
        <div className="m-0 row d-flex justify-content-center">
          <div className="col-lg-6 px-md-0 px-4 text-center mt-5 pt-3">
            <img
              src="/rocket/afores/static/assets/icons/ScreenIcon.svg"
              alt=""
            />
            <TitlePerfiladorAfore
              m="3rem 0"
              mFontSize="30px"
              mLineHeight="35.8px"
              fontf="SF UI Light"
            >
              ¡Continúa tu traspaso a Principal Afore!
            </TitlePerfiladorAfore>
          </div>
          <div className="col-lg-10  px-5">
            <ItemRow>
              <CustomParagraph
                maxWidth="850px"
                clr="#000000"
                fs="18px"
                tal="center"
                fontw="300 !important"
                ff="SF UI"
              >
                Puedes realizar tu proceso de traspaso a través de nuestra
                aplicación Principal® México,{" "}
                <b className="strongp">¡descárgala ahora!</b>
              </CustomParagraph>
            </ItemRow>
          </div>
          <div className="col-lg-3 col-md-4">
            <ItemRow m="25px 0 0 0">
              <ItemCol m="auto">
                <a
                  target="_blank"
                  href="https://bit.ly/3dKTc93"
                  rel="noreferrer"
                  onClick={() => eventoGTM("Descargar_Google_Play")}
                  className="w-100 text-center"
                >
                  <img
                    src={googlePlay}
                    alt="Principal App on Playstore"
                    style={{ maxWidth: "135px" }}
                  />
                </a>
              </ItemCol>
              <ItemCol m="auto">
                <a
                  target="_blank"
                  href="https://apple.co/3wsSSlN"
                  rel="noreferrer"
                  onClick={() => eventoGTM("Descargar_AppStore")}
                  className="w-100 text-md-end text-center"
                >
                  <img
                    // src="/rocket/afores/static/assets/images/AppStore.png"
                    src={appStore}
                    alt="Principal App on App Store"
                    style={{ maxWidth: "122px" }}
                  />
                </a>
              </ItemCol>
          </ItemRow>
          </div>
          <ItemRow m=" 25px 0 70px !important" alignIt=" " fd800="column">
            <ItemCol w="32%" mw="470px" mw800="370px" w800="100%" m800="auto">
              <ItemRow>
                <Title
                  fontSize="30px"
                  maxWidth760="100%"
                  maxWidth="350px"
                  fontFamily="SF UI"
                >
                  Pasos para realizar tu traspaso de Afore
                </Title>
              </ItemRow>
              <ItemWrapper
                m="59px 0px 0px 0px"
                dis="block"
                dis900="block"
                maxWidth="450px"
              >
                {stepsToTransferAfore.map((step, idx) => {
                  const { name, image } = step;
                  return (
                    <ItemRow mh="85px" fw=" ">
                      <ItemCol w="18%" dis="flex">
                        <img src={image} alt="" />
                      </ItemCol>
                      <ItemCol w="85%" mw="350px">
                        <CustomParagraph
                          lh="26px"
                          clr="#333333"
                          fs="20px"
                          ff="SF UI"
                        >
                          {name}
                        </CustomParagraph>
                      </ItemCol>
                    </ItemRow>
                  );
                })}
              </ItemWrapper>
            </ItemCol>

            <ItemCol
              w="45%"
              mw="380px"
              mw800="350px"
              m=" 0 20px"
              w800="100%"
              m800="40px auto 10px"
            >
              <ItemRow dis800="none" dis500="none">
                <Title
                  fontSize="28px"
                  fsize800="22px"
                  fontw="500"
                  fontw800="700"
                  maxWidth760="100%"
                  maxWidth="400px"
                  fontFamily="SF UI"
                >
                  Escanea el código QR con tu celular y descarga gratis nuestra
                  aplicación <b className="strongp">Principal® México</b>
                </Title>
              </ItemRow>
              <ItemRow dis="none" dis800="flex">
                <Title
                  mb800="10px !important"
                  fontSize="28px"
                  fsize800="22px"
                  fontw="500"
                  fontw800="700"
                  maxWidth760="100%"
                  maxWidth="400px"
                  fontFamily="SF UI"
                >
                  Descarga la app gratis.
                </Title>
              </ItemRow>
              <ItemWrapper
                maxWidth="295px"
                mar="auto"
                dis="block"
                dis900="block"
                height=" "
              >
                <ContainerButton mt="-15px" disM="none" ml="10%" mb="15px">
                  <Btn
                    bg={isAndroid === true ? "#FFFFFF" : ""}
                    br={isAndroid === true ? "24px" : ""}
                    bs={
                      isAndroid === true
                        ? "0px 0px 12px rgba(0, 40, 85, 0.25)"
                        : ""
                    }
                    onClick={() => {
                      setIsAndroid(true);
                      eventoGTM("Es_android");
                    }}
                  >
                    Android
                  </Btn>
                  <Btn
                    bg={isAndroid === false ? "#FFFFFF" : ""}
                    br={isAndroid === false ? "24px" : ""}
                    bs={
                      isAndroid === false
                        ? "0px 0px 12px rgba(0, 40, 85, 0.25)"
                        : ""
                    }
                    onClick={() => {
                      setIsAndroid(false);
                      eventoGTM("Es_ios");
                    }}
                  >
                    IOS
                  </Btn>
                </ContainerButton>
                {isAndroid === true && (
                  <ItemRow dis800="none" dis500="none">
                    <img
                      src="/rocket/afores/static/assets/images/qrGoogle.svg"
                      //"https://principal.com.mx/rocket/afores/static/media/qrImage.be5e47a5af08b7ce0855.png" alt="Principal App on GooglePlay"
                      alt="Principal App on GooglePlay"
                      className="Qr"
                    />
                  </ItemRow>
                )}
                {isAndroid === false && (
                  <ItemRow dis800="none" dis500="none">
                    <img
                      src="/rocket/afores/static/assets/images/qrApple.svg"
                      //"https://principal.com.mx/rocket/afores/static/media/qrAppStore.4152b8b0d4a92d7352a2.png" alt="Principal App on Playstore"
                      alt="Principal App on Playstore"
                      className="Qr"
                    />
                  </ItemRow>
                )}
              </ItemWrapper>
            </ItemCol>
          </ItemRow>
        </div>
      </ItemWrapper>
      {/*
      <Contacto />
      */}
    </>
  );
}

export default PerfiladorCuentaV2;
