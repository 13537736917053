import {
    ContainerSection,
    Button,
    ContainerItems,
    ItemWrapper,
    ItemRow,
    CustomParagraph,
    TitlePerfiladorAfore,
    ItemCol,
    ItemWrapperDownloadSection,
    ItemWrapperaForeSearchSectionTwo,
  } from "../../assets/styles/StylesGeneric.styled";
  import googlePlay from "../../assets/images/GooglePlay.png";
  import appStore from "../../assets/images/AppStore.png";
  import { useNavigate } from "react-router-dom";
  import TagManager from "react-gtm-module";
  
  const eventoGTM = (event, response) => {
    const tagManagerArgs = {
      dataLayer: {
        event,
        response,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };
  
  const SearchAforeCardTwo = () => {
    const navigate = useNavigate();
  
    return (
      <ContainerSection>
        <ContainerItems
          mw="100%"
          m="auto"
          disMob="column-reverse"
          fdir="row"
          fdM="column"
          ai="unset"
        >
          <ItemWrapper w="50%" bgcolor="#002855" height=" " jc="flex-end">
            <div className="py-md-4 py-3 pe-md-5 me-md-5">
              <div className="me-md-5 pe-md-1">
                <ContainerItems mw="34rem" fw="wrap" alc="center" pad="0 25px">
                  <ItemRow m="15px 0" txal="left" jc="start">
                    <CustomParagraph
                      fontw="700"
                      tal="left"
                      fs="30px"
                      talMob="center"
                      displayMob="none"
                     
                    >
                      ¿Cómo saber si mi Afore está en Principal
                      <span className="spanr2">®</span>?
                    </CustomParagraph>
                    <CustomParagraph
                    displayDes="none"
                    displayMob="block"
                      fontw="700"
                      tal="left"
                      fs="30px"
                      talMob="center"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/busqueda-afore");
                        eventoGTM("CTA_Buscar-Mi-Afore");
                      }}
                    >
                      ¿Cómo saber si mi Afore está en Principal
                      <span className="spanr2">®</span>?
                    </CustomParagraph>
                  </ItemRow>
                  <ItemRow m="15px 0" jc="start">
                    <CustomParagraph tal="leftr" fs="24px" talMob="center">
                      Solo da clic en el botón y descubre si tu Afore se encuentra
                      con nosotros.
                    </CustomParagraph>
                  </ItemRow>
                  <ItemRow m="15px 0" jc="start">
                    <Button
                      clr="#0061A0"
                      bgck="#fff"
                      minWidth="0px"
                      w="224px"
                      h="48px"
                      pad="6.5px 24px"
                      wMob="100%"
                      maxwMob="200px"
                      mt="0.5rem"
                      mMob900="30px auto 0px"
                      mbMob=" "
                      ff="eliotprobold"
                      fontw="500"
                      HoverbackgroundColor="#F6F6F8"
                      hColor="#004887"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/busqueda-afore");
                        eventoGTM("CTA_Buscar-Mi-Afore");
                      }}
                    >
                      Buscar mi Afore
                    </Button>
                  </ItemRow>
                </ContainerItems>
              </div>
            </div>
          </ItemWrapper>

          <ItemWrapperaForeSearchSectionTwo
            w="50%"
            height=" "
            jc="flex-start"
            minHeightM="300px"
            onClick={(e) => {
                e.preventDefault();
                navigate("/busqueda-afore");
                eventoGTM("CTA_Buscar-Mi-Afore");
              }}
          />
   
        </ContainerItems>
      </ContainerSection>
    );
  };

  export default  SearchAforeCardTwo;