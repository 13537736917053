import React, { Fragment } from "react";
import {
  FooterContainerNav,
  FooterRow,
  FooterCol,
  FooterLink,
  Divider,
} from "../../assets/styles/Footer.styled";
const FooterNav = () => {
  return (
    <Fragment>
      <FooterContainerNav>
        <FooterRow className="socials">
          <FooterCol>
            <a
              target="_blank"
              href="https://www.facebook.com/principal.mexico.oficial/"
              rel="noreferrer"
            >
              <img
                width="24px"
                height="24px"
                src="/rocket/afores/static/assets/images/SectionShare/FaceBook.svg"
                alt="LogoFacebook"
              />
            </a>
          </FooterCol>
          <FooterCol>
            <a
              target="_blank"
              href="https://twitter.com/Principal_mx"
              rel="noreferrer"
            >
              <img
                width="24px"
                height="24px"
                src="/rocket/afores/static/assets/images/SectionShare/Twitter.svg"
                alt="LogoTwitter"
              />
            </a>
          </FooterCol>
          <FooterCol>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/principal-financial-group-m%C3%A9xico/mycompany/"
              rel="noreferrer"
            >
              <img
                width="24px"
                height="24px"
                src="/rocket/afores/static/assets/images/SectionShare/Linkedin.svg"
                alt="LogoLinkedin"
              />
            </a>
          </FooterCol>
          <FooterCol>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UC891ECUCkClKOeSoKGB1kSQ"
              rel="noreferrer"
            >
              <img
                width="24px"
                height="24px"
                src="/rocket/afores/static/assets/images/SectionShare/Youtube.svg"
                alt="LogoYoutube"
              />
            </a>
          </FooterCol>
        </FooterRow>
        <FooterRow className="contact-number">
          <FooterCol>
            <img
              width="17px"
              height="17px"
              src="/rocket/afores/static/assets/images/SectionShare/phone.svg"
              alt="LogoPhone"
            />
          </FooterCol>
          <FooterCol>81-8056-4624</FooterCol>
        </FooterRow>
        <FooterRow className="faq">
          <FooterCol>
            <FooterLink
              target="_blank"
              href="https://www.principal.com.mx/avisos/aviso-de-privacidad"
            >
              Privacidad
            </FooterLink>
          </FooterCol>
          <Divider />
          <FooterCol>
            <FooterLink
              target="_blank"
              href="https://www.principal.com.mx/mapa-de-sitio"
            >
              Mapa del sitio
            </FooterLink>
          </FooterCol>
        </FooterRow>
        <FooterRow className="rights" m="10px 0 40px">
          2022, Principal México, Todos los derechos reservados.
        </FooterRow>
      </FooterContainerNav>
    </Fragment>
  );
};

export default FooterNav;
