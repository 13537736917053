import React, { Fragment } from "react";


import FrontSectionTwo from "./SectionsV1.1/FrontSectionV1.1";
import SectionTwo from "./SectionsV1.1/SectionTwoV1.1";
import SectionThree from "./SectionsV1.1/SectionThreeV1.1";
import SectionFour from './SectionsV1.1/SectionTwoV2Bis';

const LpFondosV2 = () => {
  return (
    <Fragment>
    <FrontSectionTwo />
    <SectionTwo />
    <SectionThree />
    <SectionFour/>
  </Fragment>
  );
};

export default LpFondosV2;
