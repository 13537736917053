/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

// import PropTypes from 'prop-types'
import {
  ContainerSection,
  TitlePerfiladorAfore,
  ContainerItems,
  Button,
  ItemCol,
  ItemRow,
  CustomParagraph,
  Title,
  ItemWrapper,
  ItemSection,
} from "../assets/styles/StylesGeneric.styled";
import { Helmet } from "react-helmet";
import Contacto from "../components/common/Contacto";
import ComponenteInput from "../components/common/Input/Input";
import { Formulario } from "../components/common/Input/elementos/Formularios";
import ScreenIcon from "../assets/icons/ScreenIcon.svg";
import DataIcon from "../assets/icons/DataIcon.svg";
import VerifyIcon from "../assets/icons/VerifyIcon.svg";
import VideoIcon from "../assets/icons/VideoIcon.svg";
import PhotoIcon from "../assets/icons/PhotoIcon.svg";
import SignIcon from "../assets/icons/SignIcon.svg";
import googlePlay from "../assets/images/GooglePlay.png";
import appStore from "../assets/images/AppStore.png";
import ScriptPerfilador from "./../utils/scripts.js";
import useScript from "./../utils/ScriptsPerfilador.js";
function PerfiladorCuenta() {
  const [nombre, setNombre] = useState({ campo: "", valido: null });
  const [apellidoPaterno, setApellidoPaterno] = useState({
    campo: "",
    valido: null,
  });
  const [apellidoMaterno, setApellidoMaterno] = useState({
    campo: "",
    valido: null,
  });
  const [telefono, setTelefono] = useState({ campo: "", valido: null });
  const [correo, setCorreo] = useState({ campo: "", valido: null });
  const [curp, setCurp] = useState({ campo: "", valido: null });

  const expresiones = {
    // usuario: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    // password: /^.{4,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{7,14}$/, // 7 a 14 numeros.
    curp: /^.{18,18}$/,
  };

  const crearCuenta = () => {
    if (
      nombre.campo !== "" &&
      nombre.valido === "true" &&
      apellidoPaterno.campo !== "" &&
      apellidoPaterno.valido === "true" &&
      apellidoMaterno.campo !== "" &&
      apellidoMaterno.valido === "true" &&
      telefono.campo !== "" &&
      telefono.valido === "true" &&
      correo.campo !== "" &&
      correo.valido === "true" &&
      curp.campo !== "" &&
      curp.valido === "true"
    ) {
      console.log("Se creará una cuenta");
    } else {
      if (nombre.campo === "") {
        setNombre({ valido: "false" });
      }
      if (apellidoPaterno.campo === "") {
        setApellidoPaterno({ valido: "false" });
      }
      if (apellidoMaterno.campo === "") {
        setApellidoMaterno({ valido: "false" });
      }
      if (telefono.campo === "") {
        setTelefono({ valido: "false" });
      }
      if (correo.campo === "") {
        setCorreo({ valido: "false" });
      }
      if (curp.campo === "") {
        setCurp({ valido: "false" });
      }
    }
  };

  const stepsToTransferAfore = [
    {
      name: "Llenar datos faltantes en la aplicación de Principal.",
      image:
        "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/DataIcon.0c21ebaff7b049f7a4d43f7182d71f99.svg",
    },
    {
      name: "Verificarás tu cuenta con Principal.",
      image:
        "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/VerifyIcon.7a7dda832d54a07da6edf6b2593d9a9f.svg",
    },
    {
      name: "Video muestra siguientes pasos.",
      image:
        "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/VerifyIcon.7a7dda832d54a07da6edf6b2593d9a9f.svg",
    },
    {
      name: "Capturar fotografía de acuerdo a lo indicado.",
      image:
        "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/PhotoIcon.83cd91e2df2bef0de08c1806d5b2692c.svg",
    },
    {
      name: "Firma de contrato de tu traspaso.",
      image:
        "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/SignIcon.26e85855d9f74d24b8026cf74e00e678.svg",
    },
  ];

  return (
    <>
      <ItemWrapper dis="block" bgcolor="#F0FBFE">
        <div className="m-0 row d-flex justify-content-center">
          <div className="col-lg-6 px-md-0 px-4 text-center mt-5 pt-3">
            <img
              src="https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/ScreenIcon.13098d7bc02dc5c7dbb15b3fd730e4a7.svg"
              alt=""
            />
            <TitlePerfiladorAfore
              m="3rem 0"
              mFontSize="30px"
              mLineHeight="35.8px"
              fontf="SF UI"
            >
              <div className="mb-3"> ¡Excelente! </div>
              Es bastante probable que cuentes con tu expediente digital
            </TitlePerfiladorAfore>
          </div>
          <div className="col-lg-7 px-5">
            <ItemRow>
              <CustomParagraph
                clr="#000000"
                fs="18px"
                tal="center"
                fontw="300 !important"
                ff="SF UI"
              >
                Un expediente digital es un registro del trabajador en el
                sistema de ahorro para el retiro, sirve para realizar de forma
                más ágil los trámites relacionados con tu cuenta de Afore.
              </CustomParagraph>
            </ItemRow>
          </div>
          <div className="col-lg-6 mt-5 pt-2">
            <Title fontSize="30px" maxWidth="100%" fontFamily="SF UI">
              Pasos para realizar tu traspaso de Afore
            </Title>
          </div>
          <div className="row d-flex justify-content-center mt-4">
            <div className="col-lg-5 col-md-9 ms-4">
              {stepsToTransferAfore.map((step, idx) => {
                const { name, image } = step;
                return (
                  <div className="row mb-4 d-flex">
                    <div className="col-md-1 col-3 me-lg-2 d-flex justify-content-center">
                      <img src={image} alt="" />
                    </div>
                    <div className="col-md-10 col-9 pt-2">
                      <p>
                        <CustomParagraph clr="#333333" fs="20px" ff="SF UI">
                          {name}
                        </CustomParagraph>
                      </p>
                    </div>
                  </div>
                );
              })}
              <div className="row my-5 py-3 d-flex justify-content-between m-0 align-items-center">
                <div className="col-md-5 text-center mb-md-0 mb-4">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.principal.fondosinversion"
                    rel="noreferrer"
                    className="w-100 text-center"
                  >
                    <img src={googlePlay} alt="Principal App on Playstore" />
                  </a>
                </div>
                <div className="col-md-5 text-center">
                  <a
                    target="_blank"
                    href="https://apps.apple.com/mx/app/principal-m%C3%A9xico/id1491050507"
                    rel="noreferrer"
                    className="w-100 text-md-end text-center"
                  >
                    <img src={appStore} alt="Principal App on App Store" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ItemWrapper>
      {/*
      <Contacto />
      */}

      {/* <Helmet>
      {(function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.async=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document,
'https://principal.ladesk.com/scripts/track.js',
function(e){ })}
          {ScriptPerfilador}
           {console.log( " aqui script ======>" , useScript("./../ScriptsPerfilador") )   }
       </Helmet>
       */}
    </>
  );
}

export default PerfiladorCuenta;
