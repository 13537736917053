import axios from "axios";

// rocket
//const ApiBaseUrl = 'https://aitpzz8ooc.execute-api.us-east-1.amazonaws.com/';
const token = sessionStorage.getItem("token_api")
  ? sessionStorage.getItem("token_api")
  : "";

// principal dev
//const ApiBaseUrl = "https://8pb362sz08.execute-api.us-west-2.amazonaws.com/";
const ApiBaseUrl = "https://ulv12rlmej.execute-api.us-east-1.amazonaws.com";

// prod principal
//const ApiBaseUrl = 'https://xh5tl9hkce.execute-api.us-east-1.amazonaws.com/'

// staging principal
//const ApiBaseUrl = 'https://d1e2rp248f.execute-api.us-east-1.amazonaws.com'

// rocket
/*const Api = axios.create({
  baseURL: ApiBaseUrl,
  headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
  }
});*/

// principal dev
const Api = axios.create({
  baseURL: ApiBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export default Api;
