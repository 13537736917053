import React, { Fragment } from "react";
import FourSection from "./sections/SectionFour";
import SectionTwo from "./sections/SectionTwo";
import SectionThree from "./sections/SectionThree";
import FrontSection from "./sections/FrontSection";
import SectionTwoV2 from "./sections/SectionTwoV2";

const LpFondos = () => {
  return (
    <Fragment>
      <FrontSection />
      <SectionTwo />
      <SectionTwoV2 />
      {/* <SectionTwoV2Bis /> */}
      {/* <SectionThree />
      <FourSection /> */}
    </Fragment>
  );
};

export default LpFondos;
