import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import { v4 } from "uuid";
import Layout from "./Layout/Layout";
import LpAfore from "./LpAfore/LpAfore";

import LpAforeV2 from "./LpAfore/LpAforeV2";
import Simulador from "./simulador/simulador";
import BusquedaAfore from "./BusquedaAfore/BusquedaAfore";
import PerfiladorCuenta from "./Perfilador/PerfiladorCuenta";
import PerfiladorContacto from "./Perfilador/PerfiladorContacto";
import DescargaApp from "./DescargaApp/DescargaApp";
import ScrollTop from "./scrollTop";
import lambdas from "./config/lambdas/lambdas";
import TagManager from "react-gtm-module";
import RedirectToUrl from "./components/common/RedirectoToUrl";
import DescargaAppBis from "./DescargaApp/DescargaAppBis";

const tagManagerArgs = {
  gtmId: "GTM-WPWFJRH",
};

TagManager.initialize(tagManagerArgs);

function App() {
  const [uuid] = useState(
    sessionStorage.getItem("uuid_principal_user") || v4()
  );
  const dataURL = () => {
    const URLsearch = window.location.search;
    const urlParams = new URLSearchParams(URLsearch);
    //Accedemos a los valores
    let utm_source = urlParams.get("utm_source");
    let utm_medium = urlParams.get("utm_medium");
    let utm_campaign = urlParams.get("utm_campaign");
    let browser = navigator.userAgent;
    const data = {
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      browser: browser,
      idSession: uuid,
    };
    tryGetAuth(data);
  };

  var tryGetAuth = function (data) {
    let intents = 0;
    let token;
    let idIntent = [];
    let flag = false;
    while (intents < 3) {
      (function (intents) {
        idIntent[intents] = setTimeout(function () {
          // console.log("itento" + intents);
          token = sessionStorage.getItem("r-token");
          if (token != null && !flag) {
            flag = true;
            lambdas.AforeLead(data, token);
          }
        }, 1000 * intents);
      })(intents++);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("uuid_principal_user", uuid);
    dataURL();
  }, [uuid]);
  return (
    <>
     <BrowserRouter basename="/cambiate-a-principal">
      <ScrollTop>
        <Layout>
          <Routes onUpdate={() => window.location.reload()}>
            <Route exact path="/" element={<LpAfore/>} />
            <Route exact path="/simulador" element={<Simulador />} />
            <Route exact path="/busqueda-afore" element={<BusquedaAfore />} />
            <Route
              exact
              path="/perfilador-cuenta"
              element={<PerfiladorCuenta />}
            />
            <Route
              exact
              path="/perfilador-contacto"
              element={<PerfiladorContacto />}
            />
            <Route exact path="/descarga-app" element={<DescargaApp />} />
          </Routes>
          <RedirectToUrl />
        </Layout>
      </ScrollTop>
    </BrowserRouter>
     <BrowserRouter basename="/cambiate-a-principal-bis">
     <ScrollTop>
       <Layout>
         <Routes onUpdate={() => window.location.reload()}>
           <Route exact path="/" element={<LpAforeV2 />} />
           <Route exact path="/simulador" element={<Simulador />} />
           <Route exact path="/busqueda-afore" element={<BusquedaAfore />} />
           <Route
             exact
             path="/perfilador-cuenta"
             element={<PerfiladorCuenta />}
           />
           <Route
             exact
             path="/perfilador-contacto"
             element={<PerfiladorContacto />}
           />
           <Route exact path="/descarga-app-bis" element={<DescargaAppBis />} />
         </Routes>
         <RedirectToUrl />
       </Layout>
     </ScrollTop>
   </BrowserRouter>
    </>
   
  );
}

export default App;
