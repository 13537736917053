import React, { Fragment } from "react";
import FormularioBusqueda from "./sections/FormularioBusqueda";
import Contacto from "../components/common/Contacto";

const BusquedaAfore = () => {
  return (
    <Fragment>
      <FormularioBusqueda />
      {/*
       <Contacto />
       */}
    </Fragment>
  );
};

export default BusquedaAfore;
