import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RedirectToUrl = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const URLsearch = window.location.search;
    const urlParams = new URLSearchParams(URLsearch);
    let section = urlParams.get("section");
    console.log("SECTION ", section);
    if (section !== null) {
      navigate("/" + section);
    }
  }, []);
};

export default RedirectToUrl;
