import "./../../assets/styles/flipcard.css";
import { useState } from "react";
import cn from "classnames";
import { ItemRow, SimpleLink } from "../../assets/styles/StylesGeneric.styled";
//import TagManager from 'react-gtm-module';

/*const eventoGTM = (event, response) => {
  const tagManagerArgs = {
    dataLayer: {
      event,
      response,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};*/

function FlipCard({ card }) {
  const [showBack, setShowBack] = useState(false);

  function handleClick() {
    if (card.variant === "click") {
      setShowBack(!showBack);
    }
  }
  return (
    <div className="flip-card" onClick={handleClick}>
      <div
        className={cn("flip-card-inner", {
          showBack,
          "hover-trigger": card.variant === "hover",
        })}
      >
        <div className="card front flip-card-front">
          <div className="card-body d-flex justify-content-center align-items-center">
            <ItemRow>
              <img
                className="card-text fs-1 fw-bold"
                src={card.src}
                alt=""
              ></img>
            </ItemRow>
            <ItemRow>
              <p className="card-text-front  fw-bold">{card.frontText}</p>
            </ItemRow>
            <ItemRow>
              {/* <img
                className="card-text fs-1 fw-bold"
                src="https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/plusIcon.7ffa752635c721ffe8b9a82093761d4f.svg"
                alt=""
              ></img> */}

              <SimpleLink fontSize="18px" fontWeight="700">
                Ver más
              </SimpleLink>
            </ItemRow>
          </div>
        </div>
        <div className="card back flip-card-back">
          <div className="card-body-back d-flex justify-content-center ">
            <ItemRow>
              <p className="card-title-back  ">{card.backTitle}</p>
            </ItemRow>
            <ItemRow>
              <p className="card-text-back  "> {card.backText1}</p>
            </ItemRow>
            <ItemRow>
              <p className="card-text-back  ">{card.backText2}</p>
            </ItemRow>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlipCard;
