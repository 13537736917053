import React from "react";
import {
  ContainerSection,
  CustomParagraph,
  ContainerItems,
  ItemRow,
  Button,
} from "../../assets/styles/StylesGeneric.styled";
import "./../../assets/styles/StylesGeneric.css";
import { useNavigate } from "react-router-dom";

const SectionThree = ({}) => {
  const navigate = useNavigate();
  return (
    <>
      <ContainerSection
        className="sectionThree"
        mh="40vh"
        fdir=" "
        h900="364px"
      >
        <ContainerItems mw="100%" fw="wrap" mtM="auto">
          <ItemRow m="10px 0">
            <CustomParagraph clr="#00EFE8" fontw="700">
              Saber si mi Afore está en Principal
            </CustomParagraph>
          </ItemRow>
          <ItemRow m="10px 0">
            <CustomParagraph
              ff="eliotprolight"
              ffM="eliotpro"
              fsMob="18px"
              talMob="center"
              wMob="90%"
              fontw="700"
            >
              Aquí puedes saber si tu Afore se encuentra con nosotros.
            </CustomParagraph>
          </ItemRow>
          <ItemRow m="10px 0">
            <Button
              clr="#0061A0"
              bgck="#fff"
              minWidth="0px"
              w="204px"
              h="48px"
              pad="6.5px 24px"
              wMob="100%"
              maxwMob="200px"
              mt="0.5rem"
              mMob900="30px auto 0px"
              mbMob=" "
              ff="eliotprobold"
              fontw="700"
              HoverbackgroundColor="#F6F6F8"
              hColor="#004887"
              onClick={(e) => {
                e.preventDefault();
                navigate("/busqueda-afore");
              }}
            >
              Buscar mi Afore
            </Button>
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                navigate("/busqueda-afore");
              }}
              bgck="#0061a000"
              bder="1px solid #fff"
              ff="eliotprolight"
              minWidth="220px"
              ffMob="eliotpro"
              hMob="48px"
              clrMob="#0061A0"
              hColor="#0061A0"
              clr="#0061A0"
              bgMob="#fff"
              wMob="85%"
              mMob="0"
              als="center"
              HoverbackgroundColor="#0061a000"
            >
              Buscar mi Afore
            </Button>
          </ItemRow>
        </ContainerItems>
      </ContainerSection>
    </>
  );
};

export default SectionThree;
