import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../../assets/styles/flipcard.css";
import FlipCard from "../components/FlipCard";
import { styled } from "@mui/material/styles";
import plusIcon from "./../../assets/icons/plusIcon.svg";

import {
  Step,
  StepConnector,
  stepConnectorClasses,
  stepLabelClasses,
  StepLabel,
  Stepper,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FlipCardMobile from "../components/FlipCardMobile";
import "./../../assets/styles/Sectiontwo.css";
const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100%",
    backgroundColor: "#F0FBFE",
    fontFamily: "eliotpro",
    [theme.breakpoints.down(768)]: {},
  },
  section: {
    maxWidth: "61.5rem",
    margin: "auto",
    padding: "70px 30px 30px",
    backgroundColor: "#F0FBFE",

    minHeight: "600px",
    [theme.breakpoints.down(450)]: {
      padding: "50px 10px 30px",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    fontWeight: "300",
    fontSize: "40px",
    fontFamily: "eliotprobold",
    textAlign: "center",
    color: "#333333",
    [theme.breakpoints.down(800)]: {
      fontWeight: "400",
      justifyContent: "center",
      fontSize: "30px",
      textAlign: "center",
    },
  },
  description: {
    display: "flex",
    justifyContent: "center",
    fontSize: "24px",
    textAlign: "center",

    [theme.breakpoints.down(800)]: {
      fontSize: "20px",
      fontWeight: "500",
      textAlign: "center",
      maxWidth: "500px",
      padding: "0 !important",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    paddingTop: "30px",
  },
  listContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "15px",
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "30px",
  },
  rowWidth: {
    width: "100%",
  },
  images: {
    width: "100%",
  },
  hideOnMovil: {
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  listRow: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    "@media (max-width: 768px)": {
      paddingBottom: "25px",
    },
  },
  item: {
    marginLeft: "15px",
  },
  itemTitle: {
    fontWeight: "700",
    fontSize: "18px",
    color: "#002855",
  },
  itemContent: {
    fontWeight: "400",
    fontSize: "18px",
  },
  justContText: {
    justifyContent: "center",
    margin: "10px 0 30px ",
    fontSize: "24px",
    [theme.breakpoints.down(800)]: {
      fontSize: "20px",
      fontWeight: "500",
      textAlign: "center",
    },
  },
  justCont: {
    justifyContent: "center",
    margin: "50px 0",
    [theme.breakpoints.down(800)]: {
      display: "none !important",
    },
  },
  justContDesc: {
    justifyContent: "center",
    margin: "35px 0 55px !important",
    [theme.breakpoints.down(800)]: {
      fontSize: "20px",
      fontWeight: "500",
      margin: "35px 0 35px !important",
    },
  },
  justContCard: {
    justifyContent: "center",
    margin: "30px 0 10px!important",
    [theme.breakpoints.up(800)]: {
      display: "none!important",
    },
  },
  justContStep: {
    justifyContent: "center",
    margin: "70px 0 10px!important",
    [theme.breakpoints.up(800)]: {
      display: "none!important",
    },
  },
}));
const steps = ["1", "2", "3"];

const CustomedConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 8,
    left: "calc(-53% + 15px)",
    right: "calc(45% + 15px)",
    [theme.breakpoints.down(800)]: {
      left: "calc(-55% + 19px)",
      right: "calc(42% + 26px)",
    },
    [theme.breakpoints.down(600)]: {
      left: "calc(-55% + 16px)",
      right: "calc(42% + 20px)",
    },
    [theme.breakpoints.down(400)]: {
      left: "calc(-55% + 14px)",
      right: "calc(42% + 18px)",
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#004C97",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    borderColor: "#004C97",
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#004C97",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#9D9EA4",
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

const CustomStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.label}`]: {
    [`&.${stepLabelClasses.completed}`]: {
      color: "#004C97",
    },
    [`&.${stepLabelClasses.active}`]: {
      color: "#004C97",
    },
    color: "#9D9EA4",
    marginTop: "5px !important",
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#004C97",
  display: "flex",
  alignItems: "center",
  ...(ownerState.active && {
    color: "#fff",
    width: 18,
    height: 18,
    borderRadius: "50%",
    border: "none",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "#004C97",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#fff",
    width: 18,
    height: 18,
    borderRadius: "50%",
    border: "2px solid #004C97",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "#004C97 !important",
  },
  "& .QontoStepIcon-circle": {
    width: 18,
    height: 18,
    borderRadius: "50%",
    border: "2px solid #9D9EA4",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
}));
function QontoStepIcon(props) {
  const { active, completed, className, index } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-completedIcon">{index + 1}</div>
      ) : (
        <div className="QontoStepIcon-circle"></div>
      )}
    </QontoStepIconRoot>
  );
}
const cards = [
  {
    id: "1",
    variant: "click",
    frontText: "Asesoría Personalizada",
    backTitle: "Asesoría Personalizada",
    backText1: (
      <span>
        Cuentas con <b className="strong"> asesoría personalizada</b> por parte
        de expertos en ahorro para ayudarte a tomar las mejores decisiones desde
        hoy y hasta tu retiro.
      </span>
    ),
    backText2: " ",
    src: "/rocket/afores/static/assets/icons/card1Img.svg",
    icon: plusIcon,
  },
  {
    id: "2",
    variant: "click",
    frontText: "Nuestra Experiencia",
    backTitle: "Nuestra Experiencia",
    backText1: (
      <span>
        Contamos con{" "}
        <b className="strong">
          más de 24 años de experiencia en el mercado financiero mexicano{" "}
        </b>{" "}
        y somos parte de Principal Financial Group Inc., que cuenta con más de
        142 años de trayectoria en el mercado financiero global.
      </span>
    ),
    backText2: "",
    back: "Back",
    src: "/rocket/afores/static/assets/icons/InternationalIcon.svg",
    icon: plusIcon,
  },
  {
    id: "3",
    variant: "click",
    frontText: "Atractivos Rendimientos",
    backTitle: "Atractivos Rendimientos",
    backText1: (
      <span>
        La diversificación de nuestros portafolios de inversión, nos permite
        buscar atractivos y consistentes rendimientos.
      </span>
    ),
    backText2: "",
    src: "/rocket/afores/static/assets/icons/card3Img.svg",
    icon: plusIcon,
  },
];

export default function SectionTwo() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [showBack, setShowBack] = useState();

  const [carrouselIndex, setCarrouselIndex] = useState({
    slideIndex: 0,
    updateCount: 0,
  });
  const slider = useRef();
  const handleStep = (step) => () => {
    setActiveStep(step);
    slider.current.slickGoTo(step);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
    afterChange: (current) => {
      setCarrouselIndex((state) => ({
        ...state,
        updateCount: current,
      }));
      setActiveStep(current);
    },
    beforeChange: (current, next) => {
      setCarrouselIndex((prev) => ({ ...prev, slideIndex: next }));
      setActiveStep(next);
    },
  };
  return (
    <div className={classes.outerWrapper}>
      <div className={classes.section}>
        <Row>
          <div className={classes.title}>
            <span>¿Por qué cambiarte a Principal Afore?, Conócenos… </span>
          </div>
        </Row>
        <Row className={classes.justContDesc}>
          <div className={classes.description}>
            Estamos comprometidos con tu futuro, conoce un poco de nosotros.
          </div>
        </Row>
        <Row className={classes.justCont} style={{ marginTop: "50px" }}>
          {cards.map((card, idx) => (
            <Col key={idx} md={4} sm={12}>
              <FlipCard key={card.id} card={card} />
            </Col>
          ))}
        </Row>
        <Row className={classes.justContCard}>
          <Slider ref={slider} {...settings}>
            {cards.map((card, idx) => (
              <Col key={idx} md={4} sm={12}>
                <div
                  key={card.id}
                  style={{ marginBottom: "2rem" }}
                  onClick={() =>
                    setShowBack((prev) => (card.id === prev ? 0 : card.id))
                  }
                >
                  <FlipCardMobile
                    card={card}
                    id={card.id}
                    showBack={showBack}
                  />
                </div>
              </Col>
            ))}
          </Slider>
        </Row>

        <Row className={classes.justContText}>
          Vale la pena intentarlo cada día, permítenos asesorarte.
        </Row>
        <Row className={classes.justContStep}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            nonLinear
            style={{ width: "100%" }}
            connector={<CustomedConnector />}
          >
            {steps.map((label, index) => {
              return (
                <Step key={label} onClick={handleStep(index)}>
                  <CustomStepLabel
                    sx={{ display: "flex", flexDirection: "column" }}
                    StepIconComponent={(props) => (
                      <QontoStepIcon index={index} {...props} />
                    )}
                  >
                    {label}
                  </CustomStepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Row>
      </div>
    </div>
  );
}
