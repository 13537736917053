import React from "react";
import {
  Input,
  Label,
  GrupoInput,
  LeyendaError,
  IconoValidacion,
  AsteriskValidacion,
} from "./elementos/Formularios";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const ComponenteInput = ({
  estado,
  cambiarEstado,
  tipo,
  label,
  placeholder,
  name,
  leyendaError,
  expresionRegular,
  funcion,
  minLengthRequired,
  maxLengthRequired,
  clrLabel,
  onChangeValidation,
  onCurpValidation,
  onTelephoneChange,
  onOnlyTextChange,
  onTextlimitChange,
  onAgeChange,
  hLabel,
  padLabel,
  disp,
  alignIt,
  fontwLabel,
  fontSize,
  w,
  maxw,
  maxw300,
  fz,
  mb,
  ml,
  ps,
  top,
  left,
  notNullValidation,
  ...props
}) => {
  const onChange = (e) => {
    if (onAgeChange) {
      if (e.target.value.length <= 2) {
        const value = e.target.value.replace(/[^0-9]/g, "");
        cambiarEstado((prevEstado) => ({
          ...prevEstado,
          campo: value,
        }));
      }
    } else if (onTelephoneChange) {
      if (e.target.value.length <= 10) {
        const value = e.target.value.replace(/\D/g, "");
        cambiarEstado((prevEstado) => ({
          ...prevEstado,
          campo: value,
        }));
      }
    } else if (onCurpValidation) {
      if (e.target.value.length <= 18) {
        const value = e.target.value;
        cambiarEstado((prevEstado) => ({
          ...prevEstado,
          campo: value,
        }));
      }
    } else if (onOnlyTextChange) {
      //only keep letters and spaces and ñ
      const value = e.target.value.replace(/[^a-zA-ZáéúíóñÑ ]/g, "");
      if (e.target.value.length <= 25) {
        cambiarEstado((prevEstado) => ({
          ...prevEstado,
          campo: value,
        }));
      }
    } else if (onTextlimitChange) {
      //only keep letters and spaces
      if (e.target.value.length <= 32) {
        const value = e.target.value;
        cambiarEstado((prevEstado) => ({
          ...prevEstado,
          campo: value,
        }));
      }
    } else if (notNullValidation) {
      //only keep letters and spaces
      if (e.target.value.length !== "" && e.target.value) {
        const value = e.target.value;
        cambiarEstado((prevEstado) => ({
          ...prevEstado,
          campo: value,
          valido: "true",
        }));
      }
    } else {
      cambiarEstado({ ...estado, campo: e.target.value });
    }
  };

  const validacion = (e) => {
    if (expresionRegular) {
      if (expresionRegular.test(estado.campo)) {
        cambiarEstado({ ...estado, valido: "true" });
      } else {
        e.preventDefault();
        cambiarEstado({ ...estado, valido: "false" });
      }
    }

    if (funcion) {
      funcion();
    }
    if (name === "curp") {
      cambiarEstado((prevEstado) => ({
        ...prevEstado,
        campo: prevEstado.campo.toUpperCase(),
      }));
    }
  };

  return (
    <div>
      <Label
        htmlFor={name}
        valido={estado.valido}
        padLabel={padLabel}
        disp={disp}
        alignIt={alignIt}
        hLabel={hLabel}
        clrLabel={clrLabel}
        fontwLabel={fontwLabel}
        fontSize={fontSize}
      >
        {label}
        <AsteriskValidacion
          ps={ps}
          top={top}
          left={left}
          valido={estado.valido}
        >
          {estado.valido === "false" ? "*" : null}
        </AsteriskValidacion>
      </Label>

      <GrupoInput>
        <Input
          type={tipo}
          placeholder={placeholder}
          id={name}
          value={estado.campo}
          onChange={onChange}
          onKeyUp={validacion}
          pattern={expresionRegular}
          onBlur={validacion}
          // onFocus={(event) => event.target.select()}
          minLength={minLengthRequired}
          required
          maxLength={maxLengthRequired}
          valido={estado.valido}
          fz={fz}
          mb={mb}
          ml={ml}
          w={w}
          maxw={maxw}
          maxw300={maxw300}
          {...props}
        />
        {/* <IconoValidacion
          icon={estado.valido === "true" ? faCheckCircle : faTimesCircle}
          valido={estado.valido}
        /> */}
      </GrupoInput>
      <LeyendaError valido={estado.valido}>{leyendaError}</LeyendaError>
    </div>
  );
};

export default ComponenteInput;
