import React, { useState } from "react";
import {
  ContainerSection,
  ItemSection,
  ContainerItems,
  ItemWrapper,
  ItemRow,
  CustomParagraph,
  CustomParagraphP,
  Button,
  Title2,
  BoxModal,
  ModalTitle,
  SubSectionForm,
  Formulario,
  ContainerButton,
  Btn,
  ContainerBox,
  ContainerIn,
  ContainerRadioBtn,
  LabelWcurp,
} from "../../assets/styles/StylesGeneric.styled";
import {
  LeyendaError,
  AsteriskValidacion,
} from "../../components/common/Input/elementos/Formularios";
import "./../../assets/styles/StylesGeneric.css";
import ComponenteInput from "../../components/common/Input/Input";
import lambdas from "../../config/lambdas/lambdas";
import { makeStyles, Modal } from "@material-ui/core";
import ClipLoader from "react-spinners/ClipLoader";
import { animateScroll as scroll } from "react-scroll";
import MenuItem from "@mui/material/MenuItem";

import TagManager from "react-gtm-module";
import ReCAPTCHA from "react-google-recaptcha";

// import ChatInput from '../components/ChatInput';
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    padding: "16px 18px",
  },
}));

const eventoGTM = (event, response) => {
  const tagManagerArgs = {
    dataLayer: {
      event,
      response,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

const FormularioBusqueda = () => {
  const [openExist, setOpenExist] = React.useState(false);
  const [openNonExist, setOpenNonExist] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const comunes = ["MARIA", "MA", "MA.", "JOSE", "J", "J."];
  const filtraInconvenientes = (str) => {
    const inconvenientes = [
      "BACA",
      "LOCO",
      "BUEI",
      "BUEY",
      "MAME",
      "CACA",
      "MAMO",
      "CACO",
      "MEAR",
      "CAGA",
      "MEAS",
      "CAGO",
      "MEON",
      "CAKA",
      "MIAR",
      "CAKO",
      "MION",
      "COGE",
      "MOCO",
      "COGI",
      "MOKO",
      "COJA",
      "MULA",
      "COJE",
      "MULO",
      "COJI",
      "NACA",
      "COJO",
      "NACO",
      "COLA",
      "PEDA",
      "CULO",
      "PEDO",
      "FALO",
      "PENE",
      "FETO",
      "PIPI",
      "GETA",
      "PITO",
      "GUEI",
      "POPO",
      "GUEY",
      "PUTA",
      "JETA",
      "PUTO",
      "JOTO",
      "QULO",
      "KACA",
      "RATA",
      "KACO",
      "ROBA",
      "KAGA",
      "ROBE",
      "KAGO",
      "ROBO",
      "KAKA",
      "RUIN",
      "KAKO",
      "SENO",
      "KOGE",
      "TETA",
      "KOGI",
      "VACA",
      "KOJA",
      "VAGA",
      "KOJE",
      "VAGO",
      "KOJI",
      "VAKA",
      "KOJO",
      "VUEI",
      "KOLA",
      "VUEY",
      "KULO",
      "WUEI",
      "LILO",
      "WUEY",
      "LOCA",
    ];
    if (inconvenientes.indexOf(str) > -1) {
      str = str.replace(/^(\w)\w/, "$1X");
    }
    return str;
  };
  const ajustaCompuesto = (str) => {
    const compuestos = [
      /\bDA\b/,
      /\bDAS\b/,
      /\bDE\b/,
      /\bDEL\b/,
      /\bDER\b/,
      /\bDI\b/,
      /\bDIE\b/,
      /\bDD\b/,
      /\bEL\b/,
      /\bLA\b/,
      /\bLOS\b/,
      /\bLAS\b/,
      /\bLE\b/,
      /\bLES\b/,
      /\bMAC\b/,
      /\bMC\b/,
      /\bVAN\b/,
      /\bVON\b/,
      /\bY\b/,
    ];
    compuestos.forEach((compuesto) => {
      if (compuesto.test(str)) {
        str = str.replace(compuesto, "");
      }
    });

    return str;
  };

  const zeropad = (ancho, num) => {
    const pad = Array.apply(0, Array.call(0, ancho))
      .map(() => 0)
      .join("");

    return (pad + num).replace(new RegExp(`^.*([0-9]{${ancho}})$`), "$1");
  };

  const primerConsonante = (str) => {
    str = str
      .trim()
      .substring(1)
      .replace(/[AEIOU]/gi, "")
      .substring(0, 1);
    return str === "" || str === "Ñ" ? "X" : str;
  };

  const filtraCaracteres = (str) =>
    str.toUpperCase().replace(/[\d_\-\.\/\\,]/g, "X");

  const estadoValido = (str) => {
    const estado = [
      "AS",
      "BC",
      "BS",
      "CC",
      "CS",
      "CH",
      "CL",
      "CM",
      "DF",
      "DG",
      "GT",
      "GR",
      "HG",
      "JC",
      "MC",
      "MN",
      "MS",
      "NT",
      "NL",
      "OC",
      "PL",
      "QT",
      "QR",
      "SP",
      "SL",
      "SR",
      "TC",
      "TS",
      "TL",
      "VZ",
      "YN",
      "ZS",
      "NE",
    ];

    return estado.indexOf(str.toUpperCase()) > -1;
  };

  const normalizaString = (str) => {
    let origen;
    let destino;
    let salida;
    origen = [
      "Ã",
      "À",
      "Á",
      "Ä",
      "Â",
      "È",
      "É",
      "Ë",
      "Ê",
      "Ì",
      "Í",
      "Ï",
      "Î",
      "Ò",
      "Ó",
      "Ö",
      "Ô",
      "Ù",
      "Ú",
      "Ü",
      "Û",
      "ã",
      "à",
      "á",
      "ä",
      "â",
      "è",
      "é",
      "ë",
      "ê",
      "ì",
      "í",
      "ï",
      "î",
      "ò",
      "ó",
      "ö",
      "ô",
      "ù",
      "ú",
      "ü",
      "û",
      "Ç",
      "ç",
    ];
    destino = [
      "A",
      "A",
      "A",
      "A",
      "A",
      "E",
      "E",
      "E",
      "E",
      "I",
      "I",
      "I",
      "I",
      "O",
      "O",
      "O",
      "O",
      "U",
      "U",
      "U",
      "U",
      "a",
      "a",
      "a",
      "a",
      "a",
      "e",
      "e",
      "e",
      "e",
      "i",
      "i",
      "i",
      "i",
      "o",
      "o",
      "o",
      "o",
      "u",
      "u",
      "u",
      "u",
      "c",
      "c",
    ];
    str = str.split("");
    salida = str.map((char) => {
      const pos = origen.indexOf(char);
      return pos > -1 ? destino[pos] : char;
    });

    return salida.join("");
  };

  const agregaDigitoVerificador = (curp_str) => {
    let curp;
    let caracteres;
    let curpNumerico;
    let suma;
    let digito;

    // Convierte el CURP en un arreglo
    curp = curp_str.substring(0, 17).toUpperCase().split("");
    caracteres = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "Ñ",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    // Convierte el curp a un arreglo de números, usando la posición de cada
    // carácter, dentro del arreglo `caracteres`.
    curpNumerico = curp.map((caracter) => caracteres.indexOf(caracter));

    suma = curpNumerico.reduce(
      (prev, valor, indice) => prev + valor * (18 - indice),
      0
    );

    digito = 10 - (suma % 10);

    if (digito === 10) {
      digito = 0;
    }

    return curp_str + digito;
  };

  const extraerInicial = (nombre) => {
    let nombres;
    let primerNombreEsComun;
    nombres = nombre.toUpperCase().trim().split(/\s+/);
    primerNombreEsComun =
      nombres.length > 1 && comunes.indexOf(nombres[0]) > -1;

    if (primerNombreEsComun) {
      return nombres[1].substring(0, 1);
    }

    return nombres[0].substring(0, 1);
  };

  const generateCURP = (param) => {
    let inicial_nombre;
    let vocal_apellido;
    let posicion_1_4;
    let posicion_14_16;
    let curp;
    let primera_letra_paterno;
    let primera_letra_materno;
    let nombres;
    let nombre_a_usar;
    let pad;
    pad = zeropad.bind(null, 2);
    if (!estadoValido(param.estado)) {
      return false;
    }
    let gen_ini = param.genero;
    const birthDay = param.fecha_nacimiento.split("/");
    param.nombre = ajustaCompuesto(
      normalizaString(param.nombre.toUpperCase())
    ).trim();
    param.apellido_paterno = ajustaCompuesto(
      normalizaString(param.apellido_paterno.toUpperCase())
    ).trim();

    param.apellido_materno = param.apellido_materno || "";
    param.apellido_materno = ajustaCompuesto(
      normalizaString(param.apellido_materno.toUpperCase())
    ).trim();

    inicial_nombre = extraerInicial(param.nombre);

    vocal_apellido = param.apellido_paterno
      .trim()
      .substring(1)
      .replace(/[BCDFGHJKLMNÑPQRSTVWXYZ]/g, "")
      .substring(0, 1);
    vocal_apellido = vocal_apellido === "" ? "X" : vocal_apellido;

    primera_letra_paterno = param.apellido_paterno.substring(0, 1);
    primera_letra_paterno =
      primera_letra_paterno === "Ñ" ? "X" : primera_letra_paterno;

    if (!param.apellido_materno || param.apellido_materno === "") {
      primera_letra_materno = "X";
    } else {
      primera_letra_materno = param.apellido_materno.substring(0, 1);
      primera_letra_materno =
        primera_letra_materno === "Ñ" ? "X" : primera_letra_materno;
    }

    posicion_1_4 = [
      primera_letra_paterno,
      vocal_apellido,
      primera_letra_materno,
      inicial_nombre,
    ].join("");

    posicion_1_4 = filtraInconvenientes(filtraCaracteres(posicion_1_4));

    nombres = param.nombre.split(" ").filter((palabra) => palabra !== "");
    nombre_a_usar =
      nombres.length > 1 && comunes.indexOf(nombres[0]) > -1
        ? nombres[1]
        : nombres[0];

    posicion_14_16 = [
      primerConsonante(param.apellido_paterno),
      primerConsonante(param.apellido_materno),
      primerConsonante(nombre_a_usar),
    ].join("");

    curp = [
      posicion_1_4,
      pad(birthDay[2] - 1900),
      pad(birthDay[1]),
      pad(birthDay[0]),
      gen_ini.toUpperCase(),
      param.estado.toUpperCase(),
      posicion_14_16,
      param.homonimia || (birthDay[2] > 1999 ? "A" : 0),
    ].join("");

    return agregaDigitoVerificador(curp);
  };
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  function handleCloseExist() {
    setOpenExist(false);
    window.location.replace(
      "https://principal.com.mx/afore?utm_source=LP+1.1_Afore&utm_medium=Form+Buscar+Afore&utm_campaign=LP+1.1"
    );
    eventoGTM("CTA_to_App");
  }
  function handleCloseExistX() {
    setOpenExist(false);
    window.location.replace(
      "https://principal.com.mx/afore?utm_source=LP+1.1_Afore&utm_medium=Form+Buscar+Afore&utm_campaign=LP+1.1"
    );
    eventoGTM("CTA_to_App");
  }
  function handleCloseNonExist() {
    setOpenNonExist(false);
    window.location.replace("/cambiate-a-principal");
    eventoGTM("CTA_to_App2");
  }
  const classes = useStyles();

  const ModalExist = () => (
    <Modal
      open={openExist}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <BoxModal style={modalStyle} className={classes.paper}>
        <ItemRow w="100%" jc="flex-end" jcM="flex-end">
          <img
            src="/rocket/afores/static/assets/icons/closeIcon.svg"
            alt=""
            onClick={handleCloseExistX}
            style={{ cursor: "pointer" }}
          />
        </ItemRow>
        <ItemRow w="100%">
          <img alt="" src="/rocket/afores/static/assets/icons/likeIcon.svg" />
        </ItemRow>
        <ItemRow w="100%">
          <ModalTitle>¡Excelente!</ModalTitle>
        </ItemRow>

        <ItemRow w="100%">
          <CustomParagraphP
            ff="SF UI"
            fs="18px"
            lh="22px"
            w="306px"
            wMob7="280px"
            clr="#333333"
            marginBottom="-10px"
          >
            Te informamos que si se encuentra tu Afore con nosotros.
          </CustomParagraphP>
        </ItemRow>
        <ItemRow w="100%">
          <CustomParagraphP
            ff="SF UI"
            fs="18px"
            lh="22px"
            w="306px"
            wMob7="280px"
            clr="#333333"
          >
            Te invitamos a conocer más sobre tu Afore.
          </CustomParagraphP>
        </ItemRow>
        <ItemRow w="100%">
          <Button
            w="135px"
            type="button"
            onClick={handleCloseExist}
            als="center"
            mb="1rem 0rem auto"
          >
            Conocer
          </Button>
        </ItemRow>
      </BoxModal>
    </Modal>
  );

  const ModalNonExist = () => (
    <Modal
      open={openNonExist}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <BoxModal style={modalStyle} className={classes.paper}>
        <ItemRow w="100%" jc="flex-end" jcM="flex-end">
          <img
            src="/rocket/afores/static/assets/icons/closeIcon.svg"
            alt=""
            onClick={handleCloseNonExist}
            style={{ cursor: "pointer" }}
          />
        </ItemRow>
        <ItemRow w="100%">
          <img src="/rocket/afores/static/assets/icons/LikeOjo.svg" alt="" />
        </ItemRow>
        <ItemRow w="100%">
          <ModalTitle maxw="250px">¡Juntos lograremos tus metas!</ModalTitle>
        </ItemRow>

        <ItemRow w="100%">
          <CustomParagraph
            ff="SF UI"
            fs="18px"
            clr="#333333"
            lh="24px"
            pad="0 0 0 7px"
          >
            No estás inscrito con nosotros pero queremos invitarte a conocer más
            sobre Principal y así podamos generar el plan ideal para tu retiro.
          </CustomParagraph>
        </ItemRow>
        <ItemRow w="100%">
          <CustomParagraph ff="SF UI" fs="18px" clr="#333333">
            Inicia tu cambio de Afore a Principal.
          </CustomParagraph>
        </ItemRow>
        <ItemRow w="100%">
          <Button
            w="135px"
            type="button"
            onClick={handleCloseNonExist}
            als="center"
            mb="1rem 0rem auto"
          >
            Continuar
          </Button>
        </ItemRow>
      </BoxModal>
    </Modal>
  );

  const [nombre, setNombre] = useState({ campo: "", valido: null });
  const [apellidoPaterno, setApellidoPaterno] = useState({
    campo: "",
    valido: null,
  });
  const [apellidoMaterno, setApellidoMaterno] = useState({
    campo: "",
    valido: null,
  });
  const [telefono, setTelefono] = useState({ campo: "", valido: null });
  const [correo, setCorreo] = useState({ campo: "", valido: null });
  const [curp, setCurp] = useState({ campo: "", valido: null });
  const [dia, setDia] = useState({ campo: "", valido: null });
  const [mes, setMes] = useState({ campo: "", valido: null });
  const [año, setAño] = useState({ campo: "", valido: null });
  const [genero, setGenero] = useState({ campo: "", valido: null });
  const [valGenero, setValGenero] = useState(false);
  const [lugarDeNacimiento, setLugarDeNacimiento] = useState({
    campo: "",
    valido: null,
  });
  const [gender, setGender] = useState("");
  const [loading, setloading] = useState(false);
  const expresiones = {
    // usuario: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    // password: /^.{4,12}$/, // 4 a 12 digitos.
    correo:
      /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i,
    telefono: /^\d{10}$/, // 7 a 10 numeros.
    dia: /^\d{1,2}$/,
    mes: /^\d{1,2}$/,
    año: /^\d{4}$/,
    lugarDeNacimiento: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
    curp: /^[A-Z]{4}\d{6}[A-Z]{6}\d{2}$/,
  };

  const [isCurp, setIsCurp] = useState(true);
  const [areTermsChecked, setAreTermsChecked] = useState(false);
  const [hasCheckedTerms, setHasCheckedTerms] = useState(false);
  const [captchaValid, setCaptchaValid] = useState(false);
  const [showErrorCaptcha, setShowErrorCaptcha] = useState(false);

  const desCurp = async (curp) => {
    const arrayCurp = [...curp];
    await setDia({ campo: `${arrayCurp[8]}${arrayCurp[9]}`, valido: true });
    await setMes({ campo: `${arrayCurp[6]}${arrayCurp[7]}`, valido: true });
    await setAño({ campo: `${arrayCurp[4]}${arrayCurp[5]}`, valido: true });
    await setLugarDeNacimiento({
      campo: `${arrayCurp[11]}${arrayCurp[12]}`,
      valido: true,
    });
    await setGender(`${arrayCurp[10]}`);
    const init_date =
      parseInt(`{arrayCurp[11]}${arrayCurp[12]}`) < 20 ? "20" : "19";
    return {
      dia_calc: `${arrayCurp[8]}${arrayCurp[9]}`,
      mes_calc: `${arrayCurp[6]}${arrayCurp[7]}`,
      anio_calc: init_date + `${arrayCurp[4]}${arrayCurp[5]}`,
      lugar_nacimiento_calc: `${arrayCurp[11]}${arrayCurp[12]}`,
      genero_calc: `${arrayCurp[10]}`,
    };
  };

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  const buscarAfore = async () => {
    sessionStorage.setItem("nombre", nombre.campo);
    sessionStorage.setItem("apellidoPaterno", apellidoPaterno.campo);
    sessionStorage.setItem("apellidoMaterno", apellidoMaterno.campo);
    sessionStorage.setItem("telefono", telefono.campo);
    sessionStorage.setItem("correo", correo.campo);
    !captchaValid ? setShowErrorCaptcha(true) : setShowErrorCaptcha(false);
    if (
      nombre.campo !== "" &&
      nombre.valido === "true" &&
      apellidoPaterno.campo !== "" &&
      apellidoPaterno.valido === "true" &&
      apellidoMaterno.campo !== "" &&
      apellidoMaterno.valido === "true" &&
      telefono.campo !== "" &&
      telefono.valido === "true" &&
      correo.campo !== "" &&
      correo.valido === "true" &&
      curp.campo !== "" &&
      curp.valido === "true" &&
      captchaValid &&
      areTermsChecked &&
      isCurp
    ) {
      sessionStorage.setItem("curp", curp.campo);
      setloading(true);
      const {
        dia_calc,
        mes_calc,
        anio_calc,
        lugar_nacimiento_calc,
        genero_calc,
      } = await desCurp(curp.campo);
      scroll.scrollToTop();
      const data = {
        nombre: nombre.campo,
        apellido_paterno: apellidoPaterno.campo,
        apellido_materno: apellidoMaterno.campo,
        celular: telefono.campo,
        email: correo.campo,
        curp: curp.campo,
        id_session: sessionStorage.getItem("uuid_principal_user"),
        gender: genero_calc,
        birthday: `${dia_calc}/${mes_calc}/${anio_calc}`,
        place_birth: lugar_nacimiento_calc,
        age: getAge(`${anio_calc}-${mes_calc}-${dia_calc} 00:00:00`),
        notice_privacy: true,
      };
      const token = sessionStorage.getItem("r-token") || "";
      lambdas
        .buscarAfore(data, token)
        .then((response) => {
          if (!response.data.Error) {
            const { existe_cliente } = response.data.Message;
            if (existe_cliente) {
              console.log("Existe PRUEBA");
              setOpenExist(true);
              setloading(false);
            } else {
              console.log("No Existe PRUEBA");
              setOpenNonExist(true);
              setloading(false);
            }
            console.log("response buscar afore", response.data.Message);
          }
        })
        .catch((error) => {
          setloading(false);
          console.log(error.data);
          alert(
            "Ha ocurrido un error en la búsqueda, por favor intente de nuevo."
          );
        });
    } else if (
      nombre.campo !== "" &&
      nombre.valido === "true" &&
      apellidoPaterno.campo !== "" &&
      apellidoPaterno.valido === "true" &&
      apellidoMaterno.campo !== "" &&
      apellidoMaterno.valido === "true" &&
      telefono.campo !== "" &&
      telefono.valido === "true" &&
      correo.campo !== "" &&
      correo.valido === "true" &&
      dia.campo !== "" &&
      mes.campo !== "" &&
      año.campo !== "" &&
      lugarDeNacimiento.campo !== "" &&
      genero.campo !== "" &&
      genero.valido === "true" &&
      captchaValid &&
      areTermsChecked &&
      !isCurp
    ) {
      setValGenero(false);
      // Peticion search afore sin CURP
      setloading(true);
      sessionStorage.setItem("gender", genero.campo);
      sessionStorage.setItem(
        "birthday",
        `${dia.campo}/${mes.campo}/${año.campo}`
      );
      sessionStorage.setItem("place_birth", lugarDeNacimiento.campo);
      sessionStorage.setItem(
        "age",
        getAge(`${año.campo}-${mes.campo}-${dia.campo} 00:00:00`)
      );
      let params = {
        nombre: nombre.campo,
        apellido_paterno: apellidoPaterno.campo,
        apellido_materno: apellidoMaterno.campo,
        estado: lugarDeNacimiento.campo,
        fecha_nacimiento: `${dia.campo}/${mes.campo}/${año.campo}`,
        genero: genero.campo,
      };
      const curpGen = generateCURP(params);
      const data = {
        nombre: nombre.campo,
        apellido_paterno: apellidoPaterno.campo,
        apellido_materno: apellidoMaterno.campo,
        celular: telefono.campo,
        email: correo.campo,
        curp: curpGen,
        id_session: sessionStorage.getItem("uuid_principal_user"),
        gender: genero.campo,
        birthday: `${dia.campo}/${mes.campo}/${año.campo}`,
        place_birth: lugarDeNacimiento.campo,
        age: getAge(`${año.campo}-${mes.campo}-${dia.campo} 00:00:00`),
        notice_privacy: true,
      };
      const token = sessionStorage.getItem("r-token") || "";
      lambdas
        .buscarAfore(data, token)
        .then((response) => {
          if (!response.data.Error) {
            const { existe_cliente } = response.data.Message;
            if (existe_cliente) {
              console.log("Existe PRUEBA");
              setOpenExist(true);
              setloading(false);
            } else {
              console.log("No Existe PRUEBA");
              setOpenNonExist(true);
              setloading(false);
            }
            console.log("response buscar afore", response.data.Message);
          }
        })
        .catch((error) => {
          setloading(false);
          console.log(error.data);
          alert(
            "Ha ocurrido un error en la búsqueda, por favor intente de nuevo."
          );
        });
    } else {
      if (genero.valido === "true") {
        setValGenero(false);
      } else {
        setValGenero(true);
      }
      if (nombre.campo === "") {
        setNombre({ campo: "", valido: "false" });
      }
      if (apellidoPaterno.campo === "") {
        setApellidoPaterno({ campo: "", valido: "false" });
      }
      if (apellidoMaterno.campo === "") {
        setApellidoMaterno({ campo: "", valido: "false" });
      }
      if (telefono.campo === "") {
        setTelefono({ campo: "", valido: "false" });
      }
      if (correo.campo === "") {
        setCorreo({ campo: "", valido: "false" });
      }
      if (curp.campo === "") {
        setCurp({ campo: "", valido: "false" });
      }
      if (genero.campo === "") {
        setGenero({ campo: "", valido: "false" });
      }
      if (dia.campo === "") {
        setDia({ campo: "", valido: "false" });
      }
      if (mes.campo === "") {
        setMes({ campo: "", valido: "false" });
      }
      if (año.campo === "") {
        setAño({ campo: "", valido: "false" });
      }
      if (lugarDeNacimiento.campo === "") {
        setLugarDeNacimiento({ campo: "", valido: "false" });
      }
      if (captchaValid) {
        setCaptchaValid(true);
      } else {
        setCaptchaValid(false);
      }
    }
  };

  const year = new Date().getFullYear();
  const years = Array.from(new Array(73), (val, index) => index - year);
  const onChangeRecaptcha = (value) => {
    if (value) {
      setCaptchaValid(true);
    }
  };
  return (
    <>
      <ContainerSection bgckM="#FFFF" mh="100vh">
        <ContainerItems
          mw="100%"
          m="auto"
          pad1300="35px 11px 35px 11px"
          pad="35px 0"
        >
          {!loading ? (
            <>
              <ItemWrapper w="38rem" wrap="wrap" oflw=" ">
                <ItemSection w="100%" m425Mob="0px 0px 5px 0px">
                  <Title2>
                    Ingresa tus datos para saber si tu Afore se encuentra con
                    Principal
                  </Title2>
                </ItemSection>
                <ItemSection
                  w="100%"
                  wrap="wrap"
                  m="0px 150px 0px 0px"
                  pad="0 0px"
                  padM="0 30px"
                  mMob="0px"
                >
                  <Formulario style={{ gap: "2rem" }} gtc="400px 400px">
                    <SubSectionForm>
                      <ComponenteInput
                        estado={nombre}
                        cambiarEstado={setNombre}
                        tipo="text"
                        placeholder=""
                        fontSize={"16px"}
                        clrLabel="#333333"
                        name="nombre"
                        padLabel="10px 0px 0px"
                        disp="flex"
                        alignIt="flex-end"
                        hLabel="20px"
                        label="Nombre (s)"
                        onOnlyTextChange
                        expresionRegular={expresiones.nombre}
                        leyendaError="Nombre inválido"
                      />
                      <ComponenteInput
                        estado={apellidoPaterno}
                        cambiarEstado={setApellidoPaterno}
                        tipo="text"
                        placeholder=""
                        clrLabel="#333333"
                        fontSize={"16px"}
                        onOnlyTextChange
                        label="Apellido Paterno"
                        name="apellido-paterno"
                        padLabel="10px 0px 0px"
                        disp="flex"
                        alignIt="flex-end"
                        hLabel="20px"
                        leyendaError="Apellido inválido"
                        expresionRegular={expresiones.nombre}
                      />
                      <ComponenteInput
                        id="formatted-numberformat-input"
                        estado={apellidoMaterno}
                        cambiarEstado={setApellidoMaterno}
                        tipo="text"
                        placeholder=""
                        clrLabel="#333333"
                        fontSize={"16px"}
                        onOnlyTextChange
                        label="Apellido Materno"
                        padLabel="10px 0px 0px"
                        disp="flex"
                        alignIt="flex-end"
                        hLabel="20px"
                        name="apelido-materno"
                        expresionRegular={expresiones.nombre}
                        leyendaError="Apellido inválido"
                      />
                      <ComponenteInput
                        estado={telefono}
                        cambiarEstado={setTelefono}
                        tipo="text"
                        placeholder=""
                        clrLabel="#333333"
                        fontSize={"16px"}
                        name="celular"
                        padLabel="10px 0px 0px"
                        disp="flex"
                        alignIt="flex-end"
                        hLabel="20px"
                        label="Celular"
                        onTelephoneChange
                        expresionRegular={expresiones.telefono}
                        leyendaError="Celular inválido"
                      />
                      <ComponenteInput
                        estado={correo}
                        cambiarEstado={setCorreo}
                        clrLabel="#333333"
                        fontSize={"16px"}
                        tipo="text"
                        placeholder=""
                        onTextlimitChange
                        padLabel="10px 0px 0px"
                        disp="flex"
                        alignIt="flex-end"
                        hLabel="20px"
                        name="nombre"
                        label="Correo Electrónico"
                        expresionRegular={expresiones.correo}
                        leyendaError="Correo inválido"
                      />
                    </SubSectionForm>

                    <SubSectionForm>
                      <ContainerButton>
                        <Btn
                          bg={isCurp === true ? "#FFFFFF" : ""}
                          br={isCurp === true ? "24px" : ""}
                          bs={
                            isCurp === true
                              ? "0px 0px 12px rgba(0, 40, 85, 0.25)"
                              : ""
                          }
                          onClick={() => {
                            setIsCurp(true);
                            eventoGTM("CTA_Con_Curp");
                          }}
                        >
                          CURP
                        </Btn>
                        <Btn
                          bg={isCurp === false ? "#FFFFFF" : ""}
                          br={isCurp === false ? "24px" : ""}
                          bs={
                            isCurp === false
                              ? "0px 0px 12px rgba(0, 40, 85, 0.25)"
                              : ""
                          }
                          onClick={() => {
                            setIsCurp(false);
                            eventoGTM("CTA_Sin_Curp");
                          }}
                        >
                          Sin CURP
                        </Btn>
                      </ContainerButton>
                      {isCurp === true && (
                        <ComponenteInput
                          estado={curp}
                          cambiarEstado={setCurp}
                          tipo="text"
                          clrLabel="#333333"
                          fontSize={"16px"}
                          onCurpValidation
                          placeholder=""
                          name="curp"
                          padLabel="10px 0px 0px"
                          disp="flex"
                          alignIt="flex-end"
                          hLabel="20px"
                          label="CURP"
                          expresionRegular={expresiones.curp}
                          leyendaError="CURP inválida"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      )}

                      {isCurp === false && (
                        <>
                          <ContainerRadioBtn>
                            <LabelWcurp dis="flex">
                              Sexo{" "}
                              <AsteriskValidacion mb="0" valido={false}>
                                {" "}
                                {valGenero ? "*" : null}
                              </AsteriskValidacion>
                            </LabelWcurp>
                            <ContainerBox fd="row" mt="5px">
                              <ContainerIn>
                                <div className="radio-liq">
                                  <input
                                    className="input-radio"
                                    style={{ padding: "0px !important" }}
                                    type="radio"
                                    id="Hombre"
                                    value="H"
                                    checked={genero.campo === "H"}
                                    onChange={(e) => {
                                      setGenero({
                                        campo: e.target.value,
                                        valido: "true",
                                      });
                                      setValGenero(false);
                                      // IsInversionLiquidez(genero);
                                    }}
                                  />
                                  <LabelWcurp htmLfor="5">Hombre</LabelWcurp>
                                </div>
                              </ContainerIn>

                              <ContainerIn ml="60px">
                                <div className="radio-liq">
                                  <input
                                    className="input-radio"
                                    style={{ padding: "0px !important" }}
                                    type="radio"
                                    id="Mujer"
                                    value="M"
                                    checked={genero.campo === "M"}
                                    onChange={(e) => {
                                      setGenero({
                                        campo: e.target.value,
                                        valido: "true",
                                      });
                                      setValGenero(false);
                                      // IsInversionLiquidez(genero);
                                    }}
                                  />
                                  <LabelWcurp htmLfor="6">Mujer</LabelWcurp>
                                </div>
                              </ContainerIn>
                            </ContainerBox>
                            {valGenero ? (
                              <LeyendaError valido="false">
                                Inválido
                              </LeyendaError>
                            ) : null}
                          </ContainerRadioBtn>

                          <LabelWcurp>Fecha de Nacimiento</LabelWcurp>

                          <div
                            style={{
                              display: "flex",
                              with: "100%",
                              justifyContent: "space-between",
                              marginBottom: "15px",
                            }}
                          >
                            <ComponenteInput
                              estado={dia}
                              cambiarEstado={setDia}
                              tipo="text"
                              label="Día"
                              padLabel="10px 0px 0px"
                              name="dia"
                              select
                              w="100%"
                              wM="105%"
                              ps="relative"
                              notNullValidation={true}
                              top="-26px"
                              left="21px"
                              expresionRegular={expresiones.dia}
                              leyendaError="Inválido"
                              // sx={{
                              //   '& .MuiMenu-paper': {
                              //    maxHeight:"calc(10% - 107px) !important",
                              //    width: "400px !important",
                              //    height: "36px !important"
                              //   }

                              // }}
                            >
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={4}>4</MenuItem>
                              <MenuItem value={5}>5</MenuItem>
                              <MenuItem value={6}>6</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={8}>8</MenuItem>
                              <MenuItem value={9}>9</MenuItem>
                              <MenuItem value={10}>10</MenuItem>
                              <MenuItem value={11}>11</MenuItem>
                              <MenuItem value={12}>12</MenuItem>
                              <MenuItem value={13}>13</MenuItem>
                              <MenuItem value={14}>14</MenuItem>
                              <MenuItem value={15}>15</MenuItem>
                              <MenuItem value={16}>16</MenuItem>
                              <MenuItem value={17}>17</MenuItem>
                              <MenuItem value={18}>18</MenuItem>
                              <MenuItem value={19}>19</MenuItem>
                              <MenuItem value={20}>20</MenuItem>
                              <MenuItem value={21}>21</MenuItem>
                              <MenuItem value={22}>22</MenuItem>
                              <MenuItem value={23}>23</MenuItem>
                              <MenuItem value={24}>24</MenuItem>
                              <MenuItem value={25}>25</MenuItem>
                              <MenuItem value={26}>26</MenuItem>
                              <MenuItem value={27}>27</MenuItem>
                              <MenuItem value={28}>28</MenuItem>
                              <MenuItem value={29}>29</MenuItem>
                              <MenuItem value={30}>30</MenuItem>
                              <MenuItem value={31}>31</MenuItem>
                            </ComponenteInput>

                            <ComponenteInput
                              estado={mes}
                              cambiarEstado={setMes}
                              tipo="text"
                              name="mes"
                              label="Mes"
                              padLabel="10px 0px 0px"
                              select
                              w="100%"
                              wM="105%"
                              ps="relative"
                              notNullValidation={true}
                              top="-26px"
                              left="27px"
                              leyendaError="Inválido "
                              expresionRegular={expresiones.mes}
                            >
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={4}>4</MenuItem>
                              <MenuItem value={5}>5</MenuItem>
                              <MenuItem value={6}>6</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={8}>8</MenuItem>
                              <MenuItem value={9}>9</MenuItem>
                              <MenuItem value={10}>10</MenuItem>
                              <MenuItem value={11}>11</MenuItem>
                              <MenuItem value={12}>12</MenuItem>
                            </ComponenteInput>

                            <ComponenteInput
                              estado={año}
                              cambiarEstado={setAño}
                              label="Año"
                              padLabel="10px 0px 0px"
                              tipo="text"
                              name="año"
                              select
                              w="100%"
                              wM="105%"
                              mb="10px"
                              ml="10px"
                              ps="relative"
                              notNullValidation={true}
                              top="-26px"
                              left="24px"
                              expresionRegular={expresiones.año}
                              leyendaError="Inválido"
                            >
                              {years.map((year, index) => (
                                <MenuItem
                                  key={`year${Math.abs(index)}`}
                                  value={Math.abs(year)}
                                >
                                  {Math.abs(year)}
                                </MenuItem>
                              ))}
                            </ComponenteInput>
                          </div>

                          <ComponenteInput
                            estado={lugarDeNacimiento}
                            cambiarEstado={setLugarDeNacimiento}
                            label="Lugar de nacimiento"
                            padLabel="10px 0px 0px"
                            tipo="text"
                            name="lugarDeNacimiento"
                            select
                            ps="relative"
                            top="-26px"
                            left="144px"
                            leyendaError="Inválido"
                            expresionRegular={expresiones.lugarDeNacimiento}
                            notNullValidation={true}
                          >
                            <MenuItem value="DF">Ciudad De Mexico</MenuItem>
                            <MenuItem value="AS">Aguascalientes</MenuItem>
                            <MenuItem value="BC">Baja California</MenuItem>
                            <MenuItem value="BS">Baja California Sur</MenuItem>
                            <MenuItem value="CC">Campeche</MenuItem>
                            <MenuItem value="CS">Chiapas</MenuItem>
                            <MenuItem value="CH">Chihuahua</MenuItem>
                            <MenuItem value="CL">Coahuila</MenuItem>
                            <MenuItem value="CM">Colima</MenuItem>
                            <MenuItem value="DG">Durango</MenuItem>
                            <MenuItem value="GT">Guanajuato</MenuItem>
                            <MenuItem value="GR">Guerrero</MenuItem>
                            <MenuItem value="HG">Hidalgo</MenuItem>
                            <MenuItem value="JC">Jalisco</MenuItem>
                            <MenuItem value="MC">Michoacán</MenuItem>
                            <MenuItem value="MS">Morelos</MenuItem>
                            <MenuItem value="MC">México</MenuItem>
                            <MenuItem value="NT">Nayarit</MenuItem>
                            <MenuItem value="NL">Nuevo León</MenuItem>
                            <MenuItem value="OC">Oaxaca</MenuItem>
                            <MenuItem value="PL">Puebla</MenuItem>
                            <MenuItem value="Q0">Querétaro</MenuItem>
                            <MenuItem value="QR">Quintana Roo</MenuItem>
                            <MenuItem value="SP">San Luis Potosí</MenuItem>
                            <MenuItem value="SL">Sinaloa</MenuItem>
                            <MenuItem value="SR">Sonora</MenuItem>
                            <MenuItem value="TC">Tabasco</MenuItem>
                            <MenuItem value="TS">Tamaulipas</MenuItem>
                            <MenuItem value="TL">Tlaxcala</MenuItem>
                            <MenuItem value="VZ">Veracruz</MenuItem>
                            <MenuItem value="YN">Yucatán</MenuItem>
                            <MenuItem value="ZS">Zacatecas</MenuItem>
                          </ComponenteInput>
                        </>
                      )}
                      <ItemSection
                        w="100%"
                        flexD="column"
                        jc="start"
                        m="35px 0 20px"
                      >
                        <div>
                          <ReCAPTCHA
                            sitekey="6LdGP6IhAAAAACwpK_I7intXec034tPkC84EV9Xn"
                            onChange={onChangeRecaptcha}
                          />
                          {showErrorCaptcha && (
                            <div className="terms-and-conditions-checkbox d-flex">
                              <span className="leyenderror">
                                Resuelva Captcha
                              </span>
                            </div>
                          )}
                          <div id="rcaptcha">
                            {/* {captchaValid == false ? <span color="#C00000" fontSize="12px">Captcha Inválido</span>: <span>  </span> } */}
                          </div>
                        </div>
                        <div className="mt-md-2">
                          <a
                            href="https://www.principal.com.mx/avisos/aviso-de-privacidad"
                            target="_blank"
                            className="terms-and-conditions d-flex"
                          >
                            <span className="sf-ui">Aviso de Privacidad* </span>
                          </a>
                          <div>
                            <label className="d-flex align-items-center mb-0">
                              <input
                                type="checkbox"
                                onChange={() => {
                                  setHasCheckedTerms(true);
                                  setAreTermsChecked(!areTermsChecked);
                                }}
                              />
                              <span className="sf-ui terms-and-conditions-checkbox ps-2 d-flex align-items-center">
                                Acepto el aviso de Privacidad
                                <AsteriskValidacion mb="0" valido={false}>
                                  {!areTermsChecked && hasCheckedTerms
                                    ? "*"
                                    : null}
                                </AsteriskValidacion>
                              </span>
                            </label>
                            {!areTermsChecked && hasCheckedTerms ? (
                              <div className="terms-and-conditions-checkbox d-flex">
                                <span className="leyenderror">Inválido</span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </ItemSection>
                    </SubSectionForm>
                  </Formulario>
                </ItemSection>
                <ItemSection w="100%" flexD=" " jc="center" m="35px 0 20px">
                  <Button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setHasCheckedTerms(true);
                      buscarAfore();
                      eventoGTM("CTA_Enviar_Buscar_Afore");
                    }}
                    hMob="48px"
                    mMob="20px 0px 0px 0px"
                    als="center"
                    minWidth="22%"
                    w="108px"
                    wMob="86%"
                    wMob425="98%"
                  >
                    Enviar
                  </Button>
                </ItemSection>
              </ItemWrapper>
              <ContainerItems w="0" m="0">
                {openExist ? <ModalExist /> : null}
                {openNonExist ? <ModalNonExist /> : null}
              </ContainerItems>
            </>
          ) : (
            <ClipLoader color="#0091DA" loading={loading} size={100} />
          )}
        </ContainerItems>
      </ContainerSection>
    </>
  );
};

export default FormularioBusqueda;
