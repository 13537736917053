import Api from "../api";

// rocket
/*const BUSCARAFORE = 'buscar-afore';
const SIMULADORAFORE = 'simulador-afore';
const MINISIMULADORAFORE= 'miniSimulador-afore';
const GETAUTH = 'get-auth';
const CREARCUENTA='/crear-cuenta';

export default {
  buscarAfore(data){
    return Api.post(BUSCARAFORE, data);
  },
  simuladorAfore(data){
    return Api.post(SIMULADORAFORE, data);
  },
  miniSimuladorAfore(data){
    return Api.post(MINISIMULADORAFORE, data);
  },
  getAuth(data){
    return Api.post(GETAUTH, data);
  },
  crearCuenta(data){
    return Api.post(CREARCUENTA, data);
  }
}*/

// principal dev
const BUSCARAFORE = "afore-buscar-afore";
const SIMULADORAFORE = "afore-simulador";
const MINISIMULADORAFORE = "afore-miniSimulador";
const GETAUTH = "getAuth";
// const AFORE_LEAD = 'afore-set-afore-lead-source'
const AFORE_LEAD = "set-afore-lead-source";
const CREARCUENTA = "afore-crear-cuenta";

export default {
  buscarAfore(data, token) {
    return Api.post(BUSCARAFORE, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  simuladorAfore(data, token) {
    return Api.post(SIMULADORAFORE, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  miniSimuladorAfore(data, token) {
    return Api.post(MINISIMULADORAFORE, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  getAuth() {
    return Api.get(GETAUTH);
  },
  crearCuenta(data, token) {
    return Api.post(CREARCUENTA, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  AforeLead(data, token) {
    return Api.post(AFORE_LEAD, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
};
