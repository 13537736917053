/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { NumberFormatCustom } from "../utils/formatNumber";
// import PropTypes from 'prop-types'
import {
  ContainerSection,
  TitleSimuladorAfore,
  ContainerItems,
  Button,
  CardAnimationTwo,
  FormContainer,
  Inp,
  Labels,
  InfoSimulador,
  InfoSimuladorMoney,
  CustomParagraph,
} from "./../assets/styles/StylesGeneric.styled";
import Contacto from "../components/common/Contacto";
import Moneda from "../assets/images/gif/Moneda.gif";
import Monedas from "../assets/images/gif/2-Varias-monedas.gif";
import Billetes from "../assets/images/gif/3-Billetes.gif";
import Bolsa from "../assets/images/gif/4-Bolsa.gif";
import ComponenteInput from "../components/common/Input/Input";
import { Formulario } from "../components/common/Input/elementos/Formularios";
import lambdas from "../config/lambdas/lambdas";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { animateScroll as scroll } from "react-scroll";

function Simulador({ price = "$0" }) {
  const [ahorroAfore, setAhorroAfore] = useState({
    campo: "",
    valido: "",
  });
  const [balance, setBalance] = useState(0);
  const [edad, setEdad] = useState({
    campo: "",
    valido: "",
  });
  const [edadRetiro, setEdadRetiro] = useState({
    campo: "",
    valido: "",
  });
  const [ahorroMensual, setAhorroMensual] = useState({
    campo: "",
    valido: "",
  });
  const [ingresoMensual, setIngresoMensual] = useState({
    campo: "",
    valido: "",
  });

  const [actualConfig, setActualConfig] = useState({
    text: "",
    bg: "",
    gif: "",
  });
  const [aforeMessage, setAforeMessage] = useState("");
  const navigate = useNavigate();

  const selectOptions = (value) => {
    switch (value) {
      case 1:
        setAforeMessage("¡Perfecto!");
        return {
          gif: "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/4-Bolsa.41cbe4969a9a4557c5b7.gif",
          text: "Justo es lo que necesitas ahorrar para lograr tus objetivos en tu vejez, sigue así.",
          bg: "linear-gradient(71.21deg, #00A29B 29.17%, #00827D 100%)",
        };
      case 2:
        setAforeMessage("Sería un gran ahorro,");
        return {
          gif: "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/2-Varias-monedas.d95111bbd1c319843c25.gif",
          text: "Pero podría ser mejor, recuerda que todo lo que logres ahorrar tú yo del futuro te lo agradecerá.",
          bg: "linear-gradient(71.21deg, #E2A700 30.73%, #FFC82E 100%)",
        };
      case 3:
        setAforeMessage("Es muy poco tu ahorro,");
        return {
          gif: "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/Moneda.2b0061bb59a97ff0cea2.gif",
          text: "Te recomendamos mejorarlo, esto sirve para tu tranquilidad en la vejez.",
          bg: "linear-gradient(71.21deg, #EA965A 29.17%, #F76800 100%)",
        };
      default:
        return {
          gif: "https://dev.afores.therocketcode.com/static/media/3-Billetes.7b8446fdb596739af518.gif",
          text: "",
          bg: "#E3F5FD",
        };
    }
  };

  useEffect(() => {
    const option = selectOptions(0);
    setActualConfig(option);
  }, []);

  const validarLongitud = (estado, cambiarEstado, min, max) => {
    if (estado.campo.length < min || estado.campo.length > max) {
      cambiarEstado({ ...estado, valido: "false" });
    } else {
      cambiarEstado({ ...estado, valido: "true" });
    }
  };
  const validarEdad = (estado, cambiarEstado, min, max) => {
    if (estado.campo >= min && estado.campo <= max) {
      cambiarEstado({ ...estado, valido: "true" });
    } else {
      cambiarEstado({ ...estado, valido: "false" });
    }
  };

  const validarDineroMin = (estado, cambiarEstado, min, max) => {
    console.log(parseInt(estado.campo));
    if (parseInt(estado.campo) >= min && estado.campo.length <= max) {
      cambiarEstado({ ...estado, valido: "true" });
    } else {
      cambiarEstado({ ...estado, valido: "false" });
    }
  };
  const [loading, setloading] = useState(false);
  const [count, setCount] = useState(0);

  const simulador = () => {
    if (
      ahorroAfore.campo !== "" &&
      ahorroAfore.valido === "true" &&
      edad.campo !== "" &&
      edad.campo !== 0 &&
      edad.valido === "true" &&
      edadRetiro.campo !== "" &&
      edadRetiro.valido === "true" &&
      ahorroMensual.campo !== "" &&
      ahorroMensual.valido === "true" &&
      ingresoMensual.campo !== "" &&
      ingresoMensual.valido === "true"
    ) {
      setCount(count + 1);
      // count >= 1 ? setloading(true) : setloading(false);
      setloading(true);
      scroll.scrollToTop();
      const data = {
        ahorroAfore: ahorroAfore.campo,
        edad: edad.campo,
        edadRetiro: edadRetiro.campo,
        ahorroMensual: ahorroMensual.campo,
        ingresoMensual: ingresoMensual.campo,
        id_session: sessionStorage.getItem("uuid_principal_user"),
      };
      // const token = lambdas
      //   .getAuth()
      //   .then((response) => {
      //     lambdas
      //       .simuladorAfore(data, response.data.token)
      //       .then((response) => {
      //         console.log("response", response);
      //         if (!response.data.Error) {
      //           const { result } = response.data.Message;
      //           setBalance(result.balance);
      //           const score_int = parseInt(result.score);
      //           let option = 0;
      //           // debugger
      //           if (score_int <= 50) {
      //             option = 3;
      //           } else if (score_int > 50 && score_int <= 70) {
      //             option = 2;
      //           } else if (score_int > 70) {
      //             option = 1;
      //           }
      //           setActualConfig(selectOptions(option));
      //           setloading(false);
      //         }
      //       })
      //       .catch((error) => {
      //         alert(
      //           "Ha ocurrido un error al hacer el calculo. Contactar al equipo de soporte"
      //         );
      //         setloading(false);
      //         console.log(error.data);
      //       });
      //   })
      //   .catch((error) => {
      //     console.log(error.data);
      //   });
      /**
       * autenticación desde el back
       */
      const token = sessionStorage.getItem("r-token") || "";
      lambdas
        .simuladorAfore(data, token)
        .then((response) => {
          console.log("response", response);
          if (!response.data.Error) {
            const { result } = response.data.Message;
            setBalance(result.balance);
            const score_int = parseInt(result.score);
            let option = 0;
            // debugger
            if (score_int <= 50) {
              option = 3;
            } else if (score_int > 50 && score_int <= 70) {
              option = 2;
            } else if (score_int > 70) {
              option = 1;
            }
            setActualConfig(selectOptions(option));
            setloading(false);
          }
        })
        .catch((error) => {
          alert(
            "Ha ocurrido un error al hacer el calculo. Contactar al equipo de soporte"
          );
          setloading(false);
          console.log(error.data);
        });
    } else {
      if (ahorroAfore.campo === "") {
        setAhorroAfore({ ...ahorroAfore, valido: "false" });
      }
      if (edad.campo === "") {
        setEdad({ ...edad, valido: "false" });
      }
      if (edadRetiro.campo === "") {
        setEdadRetiro({ ...edadRetiro, valido: "false" });
      }
      if (ahorroMensual.campo === "") {
        setAhorroMensual({ ...ahorroMensual, valido: "false" });
      }
      if (ingresoMensual.campo === "") {
        setIngresoMensual({ ...ingresoMensual, valido: "false" });
      }
    }
  };

  return (
    <>
      <div
        style={{ background: actualConfig.bg }}
        className="row m-0 px-md-0 px-2"
      >
        <TitleSimuladorAfore
          h="auto"
          mBottom="3rem"
          mt="3rem"
          mFontSize="30px"
          mLineHeight="35.8px"
          style={{ marginTop: "3rem" }}
        >
          Descubre cuanto podrías ganar en el tiempo que tú{" "}
          <br className="d-md-block d-none" /> decidas
        </TitleSimuladorAfore>
        <div className="col-12">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-md-6 pe-4">
                  <form style={{ gap: "3rem", height: "100%" }}>
                    {!loading ? (
                      <div className="w-100">
                        <div>
                          <ComponenteInput
                            cambiarEstado={setAhorroAfore}
                            estado={ahorroAfore}
                            placeholder=""
                            hLabel=" "
                            padLabel="0px 0 8px"
                            type="money"
                            label={
                              <span
                                style={{
                                  fontFamily: "eliotpro",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "19px",
                                }}
                              >
                                ¿A cuánto crees que asciende tu ahorro en tu
                                Afore? <br className="d-md-block d-none" />
                                (Aproximado)
                              </span>
                            }
                            name="ahorro_afore"
                            leyendaError={
                              ahorroAfore.campo.length > 9
                                ? "Solo se permiten menos de 9 caracteres"
                                : "Campo obligatorio"
                            }
                            funcion={() =>
                              validarLongitud(ahorroAfore, setAhorroAfore, 1, 9)
                            }
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                          />
                          <ComponenteInput
                            cambiarEstado={setEdad}
                            estado={edad}
                            hLabel=" "
                            padLabel="24px 0px 5px"
                            type="number"
                            label={
                              <span
                                style={{
                                  fontFamily: "eliotpro",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "19px",
                                }}
                              >
                                ¿Cuál es tu edad?
                              </span>
                            }
                            placeholder=""
                            onAgeChange
                            name="edad"
                            funcion={() => validarEdad(edad, setEdad, 18, 60)}
                            leyendaError="Requiere una edad mayor a 18 años y menor a 60 años"
                          />
                          <ComponenteInput
                            cambiarEstado={setEdadRetiro}
                            estado={edadRetiro}
                            type="text"
                            hLabel=" "
                            onAgeChange
                            padLabel="24px 0px 5px"
                            placeholder=""
                            label={
                              <span
                                style={{
                                  fontFamily: "eliotpro",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "19px",
                                }}
                              >
                                ¿A qué edad te gustaría retirarte?
                              </span>
                            }
                            funcion={() =>
                              validarEdad(edadRetiro, setEdadRetiro, 60, 65)
                            }
                            leyendaError="Requiere una edad entre 60 y 65 años"
                            name="edad-retiro"
                          />
                          <ComponenteInput
                            cambiarEstado={setAhorroMensual}
                            hLabel=" "
                            estado={ahorroMensual}
                            padLabel="24px 0px 5px"
                            placeholder=""
                            label={
                              <span
                                style={{
                                  fontFamily: "eliotpro",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "19px",
                                }}
                              >
                                ¿Cuánto puedes ahorrar mensual?
                              </span>
                            }
                            leyendaError="Requiere un mínimo de $500"
                            funcion={() =>
                              validarDineroMin(
                                ahorroMensual,
                                setAhorroMensual,
                                500,
                                9
                              )
                            }
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                            name="ahorro-mensual"
                          />
                          <ComponenteInput
                            cambiarEstado={setIngresoMensual}
                            hLabel=" "
                            padLabel="24px 0px 5px"
                            estado={ingresoMensual}
                            placeholder=""
                            label={
                              <span
                                style={{
                                  fontFamily: "eliotpro",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "19px",
                                }}
                              >
                                ¿Cuál es tu ingreso mensualmente?
                              </span>
                            }
                            funcion={() =>
                              validarDineroMin(
                                ingresoMensual,
                                setIngresoMensual,
                                1000,
                                9
                              )
                            }
                            leyendaError="Requiere un mínimo de $1,000"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                            name="ingreso-mensual"
                          />
                          {balance ? (
                            <div className="afore-value-container">
                              <InfoSimulador className="text-md-start text-center">
                                Podrías tener en tu Afore
                              </InfoSimulador>
                              <InfoSimuladorMoney
                                style={{
                                  color: balance ? "white" : "",
                                }}
                                className="text-md-start text-center"
                              >
                                {new Intl.NumberFormat("ES-MX", {
                                  style: "currency",
                                  currency: "MXN",
                                }).format(balance)}
                              </InfoSimuladorMoney>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        align="center"
                        className="d-flex justify-content-center align-items-center loading h-100"
                        style={{ marginRight: "8rem" }}
                      >
                        <ClipLoader
                          color={
                            actualConfig.bg === "#E3F5FD"
                              ? "#0091DA"
                              : "#FFFFFF"
                          }
                          loading={loading}
                          size={100}
                        />
                      </div>
                    )}
                  </form>
                </div>
                <div className="col-md-6 px-md-4 px-3 pt-md-5">
                  <CardAnimationTwo
                    width="100%"
                    padding="0px"
                    height="auto"
                    bgColor={
                      actualConfig.bg === "#E3F5FD" ? "#E3F5FD" : "#ffffff"
                    }
                    boxShadow={actualConfig.bg === "#E3F5FD" ? "unset" : ""}
                  >
                    <div className="text-center mx-md-5">
                      <div className="d-flex justify-content-center">
                        <div className="mt-5">
                          <img
                            className="rounded-image"
                            src={actualConfig.gif}
                            alt=""
                            width="170px"
                          />
                        </div>
                      </div>
                      {aforeMessage ? (
                        <div className="my-5 px-md-0 px-4">
                          <CustomParagraph
                            fs="24px"
                            clr="#000"
                            tal="center"
                            fsMob="20px"
                            ffMob="SF UI"
                          >
                            {aforeMessage}
                          </CustomParagraph>
                          <CustomParagraph
                            fs="24px"
                            clr="#000"
                            tal="center"
                            fsMob="20px"
                            ffMob="SF UI"
                          >
                            {actualConfig.text}
                          </CustomParagraph>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardAnimationTwo>
                </div>
                <div className="col-12 text-center my-5 pt-3">
                  <Button
                    mb="1rem 0rem auto"
                    minWidth="181px"
                    onClick={!balance ? simulador : () => navigate("/")}
                  >
                    Continuar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <TitleSimuladorAfore mt="3rem">
          Descubre cuanto podrías ganar en el tiempo que tú <br /> decidas
        </TitleSimuladorAfore> */}
        {/* <ContainerItems ai="start" pad="3.5rem 0rem 4rem">
          <Formulario style={{ gap: "3rem" }}>
            {!loading ? (
              <>
                <div>
                  <ComponenteInput
                    cambiarEstado={setAhorroAfore}
                    estado={ahorroAfore}
                    placeholder=""
                    hLabel=" "
                    padLabel="0px 0 8px"
                    type="money"
                    label={
                      <span
                        style={{
                          fontFamily: "eliotpro",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "19px",
                        }}
                      >
                        ¿A cuánto crees que asciende tu ahorro en tu Afore?{" "}
                        <br />
                        (Aproximado)
                      </span>
                    }
                    name="ahorro_afore"
                    leyendaError={
                      ahorroAfore.campo.length > 9
                        ? "Solo se permiten menos de 9 caracteres"
                        : "Campo obligatorio"
                    }
                    funcion={() =>
                      validarLongitud(ahorroAfore, setAhorroAfore, 1, 9)
                    }
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                  <ComponenteInput
                    cambiarEstado={setEdad}
                    estado={edad}
                    hLabel=" "
                    padLabel="24px 0px 5px"
                    type="number"
                    label={
                      <span
                        style={{
                          fontFamily: "eliotpro",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "19px",
                        }}
                      >
                        ¿Cuál es tu edad?
                      </span>
                    }
                    placeholder=""
                    onChangeValidation
                    name="edad"
                    funcion={() => validarEdad(edad, setEdad, 18, 60)}
                    leyendaError="Requiere una edad mayor a 18 años y menor a 60 años"
                  />
                  <ComponenteInput
                    cambiarEstado={setEdadRetiro}
                    estado={edadRetiro}
                    type="number"
                    hLabel=" "
                    padLabel="24px 0px 5px"
                    placeholder=""
                    label={
                      <span
                        style={{
                          fontFamily: "eliotpro",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "19px",
                        }}
                      >
                        ¿A qué edad te gustaría retirarte?
                      </span>
                    }
                    funcion={() =>
                      validarEdad(edadRetiro, setEdadRetiro, 60, 99)
                    }
                    leyendaError="Requiere una edad mayor a 60 años"
                    name="edad-retiro"
                  />
                  <ComponenteInput
                    cambiarEstado={setAhorroMensual}
                    hLabel=" "
                    estado={ahorroMensual}
                    padLabel="24px 0px 5px"
                    placeholder=""
                    label={
                      <span
                        style={{
                          fontFamily: "eliotpro",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "19px",
                        }}
                      >
                        ¿Cuánto puedes ahorrar mensual?
                      </span>
                    }
                    leyendaError="Requiere un mínimo de $500"
                    funcion={() =>
                      validarDineroMin(ahorroMensual, setAhorroMensual, 500, 9)
                    }
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    name="ahorro-mensual"
                  />
                  <ComponenteInput
                    cambiarEstado={setIngresoMensual}
                    hLabel=" "
                    padLabel="24px 0px 5px"
                    estado={ingresoMensual}
                    placeholder=""
                    label={
                      <span
                        style={{
                          fontFamily: "eliotpro",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "19px",
                        }}
                      >
                        ¿Cuál es tu ingreso mensualmente?
                      </span>
                    }
                    funcion={() =>
                      validarDineroMin(
                        ingresoMensual,
                        setIngresoMensual,
                        1000,
                        9
                      )
                    }
                    leyendaError="Requiere un mínimo de $1,000"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    name="ingreso-mensual"
                  />

                  <InfoSimulador>Podrías tener en tu Afore</InfoSimulador>
                  <InfoSimuladorMoney
                    style={{
                      color: balance ? "white" : "",
                    }}
                  >
                    {new Intl.NumberFormat("ES-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(balance)}
                  </InfoSimuladorMoney>
                </div>
              </>
            ) : (
              <div
                align="center"
                className="d-flex justify-content-center align-items-center"
                style={{ marginRight: "10rem" }}
              >
                <ClipLoader
                  color={actualConfig.bg === "#E3F5FD" ? "#0091DA" : "#FFFFFF"}
                  loading={loading}
                  size={100}
                />
              </div>
            )}
            <CardAnimationTwo>
              <img src={actualConfig.gif} alt="" width="170px" />
              <CustomParagraph fs="24px" clr="#000" tal="center">
                {actualConfig.text}
              </CustomParagraph>
            </CardAnimationTwo>
          </Formulario>
        </ContainerItems>
        <Button mb="1rem 0rem auto" onClick={simulador}>
          Continuar
        </Button> */}
      </div>
      {/*
          <Contacto />
      */}
    </>
  );
}

// LpAfore.propTypes = {}

export default Simulador;
