import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import {
  MainHeader,
  Logo,
  ContainerItems,
} from "../../assets/styles/Header.styled";

const Header = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <MainHeader>
        <ContainerItems jc="flex-start">
          <Logo
            src="/rocket/afores/static/assets/images/LogoHeader.svg"
            width="50%"
            alt="logo"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer", width: "150" }}
          />
        </ContainerItems>
      </MainHeader>
    </>
  );
};

export default Header;
