import React, { useEffect, useState } from "react";
import {
  ContainerItems,
  TextCarousel,
  CardCarouselItem,
  TextItem,
  TextCantidad,
  ContainerBoxCard,
  Paragraph30,
  TextSmall,
  ContainerCtaBox,
  ContainerBoxLeft,
  Button,
  TextCarouselTwo,
  FormContainer,
  BoxStyle,
  BoxTitle,
  BoxDescription,
  CardOneSubtitle,
  CustomParagraph,
} from "../../assets/styles/StylesGeneric.styled";
import AnimationBox from "./../components/AnimationBox";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { makeStyles, Modal } from "@material-ui/core";
import { Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ComponenteInput from "../../components/common/Input/Input";
import { Formulario } from "../../components/common/Input/elementos/Formularios";
import { NumberFormatCustom } from "../../utils/formatNumber";
import Monedas2 from "../../assets/icons/Monedas2.png";

const SliderAfore = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    backgroundColor: "#00EFE8",
  },

  "& .MuiSlider-thumb": {
    backgroundColor: "#00EFE8",
    margin: "0px 0px 40px 0px",
  },

  "& .MuiSlider-valueLabel": {
    background: "#00EFE8",
    padding: "8px 16px",
    color: "#0076CF",
    fontSize: "18px",
    //fontFamily: "eliotpro",
    fontWeight: 400,
    width: 100,
    height: 40,
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    transformOrigin: "bottom left",
    // transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": {
      //display: "none"
    },
    //"&.MuiSlider-valueLabelOpen": {
    // transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    //},
    //"& > *": {
    //transform: "rotate(45deg)",
    //},

    "& .MuiSlider-rail": {
      color: "#00EFE8",
    },
    "& .MuiSlider-mark": {
      backgroundColor: "#00EFE8",
    },
  },
});
const marks = [
  {
    value: 0,
  },
  {
    value: 60000,
  },
];

if (marks.value === 40) {
  alert("hola mundo ");
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));
// import MonedaBox from "./../../assets/img/MonedaBox.svg";
export const CardOne = ({ handleClick, onChangeAnimation, setValue }) => {
  /*const [input, setInput] = useState("");
  const handleOnChange = (event) => {
    setInput(event.target.value);
  };*/
  const [animation, setAnimation] = useState(
    "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/Moneda.2b0061bb59a97ff0cea2.gif"
  );
  const [sliderValue, setSliderValue] = useState(0);
  const optionsMoneyFormat = { style: "currency", currency: "MXN" };
  const numberFormat = new Intl.NumberFormat("es-MX", optionsMoneyFormat); //.replace(".00", "")

  const valuetext = (value) => {
    setValue(value);
    if (value !== sliderValue) {
      setSliderValue(value);
      if (0 <= value && value <= 14999) {
        setAnimation(
          "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/Moneda.2b0061bb59a97ff0cea2.gif"
        );
        onChangeAnimation(
          "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/Moneda.2b0061bb59a97ff0cea2.gif"
        );
      }
      if (15000 <= value && value <= 29999) {
        setAnimation(
          "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/2-Varias-monedas.d95111bbd1c319843c25.gif"
        );
        onChangeAnimation(
          "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/2-Varias-monedas.d95111bbd1c319843c25.gif"
        );
      }
      if (30000 <= value && value <= 44999) {
        setAnimation(
          "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/3-Billetes.7b8446fdb596739af518.gif"
        );
        onChangeAnimation(
          "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/3-Billetes.7b8446fdb596739af518.gif"
        );
      }
      if (value >= 45000) {
        setAnimation(
          "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/4-Bolsa.41cbe4969a9a4557c5b7.gif"
        );
        onChangeAnimation(
          "https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/4-Bolsa.41cbe4969a9a4557c5b7.gif"
        );
      }
    }
    return `${value}$`;
  };

  /*function ValueLabelComponent(props) {
    const { children, value } = props;

    return (
      <Tooltip
        open
        placement="top"
        title={Intl.NumberFormat("ES-MX", {
          style: "currency",
          currency: "MXN",
        })
          .format(value)
          .replace(".00", "")}
        arrow
      >
        {children}
      </Tooltip>
    );
  }*/

  return (
    <>
      <ContainerItems
        fdir="column"
        m="100px 0 0 0"
        mw="100%"
        mtM="64px"
        wM="99%"
        pM="4%"
      >
        <TextCarousel>
          ¿Cuándo tengas 60 años, cuánto dinero te gustaría tener mensualmente?
        </TextCarousel>
        <ContainerItems
          m="50px 0px 0px 226px"
          mM="0px 0px 0px 0px"
          mw="75%"
          jc="center"
          disMob="column"
          mwM="85%"
        >
          <ContainerItems fdir="column" mw="35rem">
            <CardOneSubtitle mbM="5.5rem" fsM="20px" fwM="700" wM="113%">
              Me gustaría tener mensualmente:
            </CardOneSubtitle>
            <ContainerItems w="90%" pos="relative">
              <CustomParagraph
                fontw="400"
                fs="18px"
                ffMob="eliotprobold"
                lh="22px"
                style={{
                  top: "2.25rem",
                  left: "-0.5rem",
                  position: "absolute",
                }}
              >
                $0
              </CustomParagraph>
              <CustomParagraph
                fontw="700"
                fs="18px"
                lh="22px"
                style={{ top: "2.25rem", right: "0", position: "absolute" }}
              >
                $60,000
              </CustomParagraph>
              <SliderAfore
                className="sliderrr"
                aria-label="Always visible"
                size="big"
                getAriaValueText={valuetext}
                valueLabelDisplay="on"
                step={500}
                min={0}
                max={60000}
                valueLabelFormat={(value) => (
                  <div>{numberFormat.format(value).replace(".00", "")}</div>
                )}
                //components={{
                //  ValueLabel: ValueLabelComponent,
                //}}
                marks={marks}
              />
            </ContainerItems>
            <Button
              clr="#0061A0"
              bgck="#fff"
              minWidth="0px"
              w="119px"
              h="32px"
              pad="6.5px 24px"
              wMob="90%"
              mt="2rem"
              mMob="50px 0px 0px 0px"
              fs="16px"
              onClick={
                sliderValue > 0
                  ? handleClick
                  : () => alert("Selecciona una cantidad")
              }
            >
              Continuar
            </Button>
          </ContainerItems>

          <ContainerItems
            m="2rem 0px 2rem 0px"
            mM="1.5rem 0rem 1rem"
            mw="40rem"
            disMob="column"
          >
            <AnimationBox animation={animation} />
          </ContainerItems>
        </ContainerItems>
      </ContainerItems>
    </>
  );
};

export const CardTwo = ({ onChange, animation, setFormData, handleClick }) => {
  const [open, setOpen] = React.useState(true);
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  function getModalStyle() {
    const top = 45;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${50}%, -${left}%)`,
    };
  }

  const ModalMessage = () => (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <BoxStyle style={modalStyle} className={classes.paper} padding="0px">
        <div className="text-end pt-3 w-100 pe-5">
          <span
            style={{
              cursor: "pointer",
              fontSize: "26px",
              transform: "transform: scale(1, 1)",
            }}
            onClick={() => setOpen(false)}
          >
            X
          </span>
        </div>
        <img
          src="https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/ModalCard2.063b58e4e0dc62846f881e73586a458f.svg"
          alt=""
        />
        <BoxTitle width="100%">¿Sabías que?</BoxTitle>
        <BoxDescription width="100%">
          <div className="px-5">
            Los Afores son una forma fácil de ahorrar para lograr esos objetivos
            que tienes en mente, lo mejor de todo es que tú decides cuanto
            ahorrar y en cuanto tiempo lo puedes ahorrar, sin plazos forzosos,
            sin perdidas.
          </div>
        </BoxDescription>
      </BoxStyle>
    </Modal>
  );

  //const [nombre, setNombre] = useState({ campo: "", valido: null });
  const [telefono, setTelefono] = useState({ campo: "", valido: null });
  const [correo, setCorreo] = useState({ campo: "", valido: null });
  const [curp, setCurp] = useState({ campo: "", valido: null });
  const [double, setDouble] = useState(false);
  const expresiones = {
    // usuario: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    // password: /^.{4,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{7,14}$/, // 7 a 14 numeros.
    curp: /^[A-Z]{4}\d{6}[A-Z]{6}\d{2}$/,
    ///^.{18,18}$/,
  };

  const [edad, setEdad] = useState({
    campo: "",
    valido: "",
  });

  const [ingresoMensual, setIngresoMensual] = useState({
    campo: "",
    valido: "",
  });
  useEffect(() => {
    if (
      edad.valido === "true" &&
      edad.campo !== "" &&
      edad.campo !== 0 &&
      telefono.valido === "true" &&
      telefono.campo !== "" &&
      correo.valido === "true" &&
      correo.campo !== "" &&
      curp.valido === "true" &&
      curp.campo !== "" &&
      ingresoMensual.valido === "true" &&
      ingresoMensual.campo !== ""
    ) {
      onChange(true);
      setDouble(false);
      setFormData({
        edad: edad.campo,
        telefono: telefono.campo,
        correo: correo.campo,
        curp: curp.campo,
        ingreso_mensual: ingresoMensual.campo,
      });
    } else {
      setDouble(true);
    }
  }, [edad, telefono, correo, curp, ingresoMensual, onChange, setFormData]);

  const validarEdad = (estado, cambiarEstado, min, max) => {
    if (estado.campo >= min && estado.campo < max) {
      cambiarEstado({ ...estado, valido: "true" });
    } else {
      cambiarEstado({ ...estado, valido: "false" });
    }
  };

  const validarDineroMin = (estado, cambiarEstado, min, max) => {
    console.log(parseInt(estado.campo));
    if (parseInt(estado.campo) >= min && estado.campo.length <= max) {
      cambiarEstado({ ...estado, valido: "true" });
    } else {
      cambiarEstado({ ...estado, valido: "false" });
    }
  };

  const validarCel = (estado, cambiarEstado, min) => {
    if (estado.campo.length === min) {
      cambiarEstado({ ...estado, valido: "true" });
    } else {
      cambiarEstado({ ...estado, valido: "false" });
    }
  };
  return (
    <>
      <ContainerItems fdir="column" m="100px 0 0 0" h=" " mtM="64px">
        <TextCarousel m="0px 0px 0px 0px" mbM="1rem">
          Ayúdame con lo siguiente
        </TextCarousel>

        <ContainerItems mw="65rem" disMob="column">
          <ContainerItems
            w="50%"
            m="35px 0px 0px 0px"
            fdir="column"
            wM="90%"
            mM="0rem"
          >
            <TextCarouselTwo m=" " pad="0 15px">
              Te pediremos algunos datos para apoyarte con más información sobre
              Afores.
            </TextCarouselTwo>

            <FormContainer
              m="34px 30px 0px 30px"
              bg_form="none"
              w="26rem"
              wM="100%"
              mM="0rem 0rem 2rem"
              h=" "
            >
              <Formulario style={{ gap: "3rem" }} gtc="1fr">
                <div>
                  <ComponenteInput
                    cambiarEstado={setEdad}
                    estado={edad}
                    type="text"
                    label="Edad"
                    padLabel="5px 0"
                    hLabel=" "
                    clrLabel="#fff"
                    fontwLabel="400"
                    onChangeValidation
                    onAgeChange
                    placeholder=""
                    border="none"
                    name="edad"
                    funcion={() => validarEdad(edad, setEdad, 18, 61)}
                    leyendaError="Requiere una edad mayor a 18 años y menor a 60 años"
                  />

                  <ComponenteInput
                    estado={telefono}
                    cambiarEstado={setTelefono}
                    tipo="number"
                    placeholder=""
                    name="nombre"
                    label="Teléfono"
                    padLabel="5px 0"
                    hLabel=" "
                    clrLabel="#fff"
                    fontwLabel="400"
                    border="none"
                    expresionRegular={expresiones.telefono}
                    funcion={() => validarCel(telefono, setTelefono, 10)}
                    leyendaError="Teléfono inválido, ingresar 10 dígitos"
                    onTelephoneChange
                  />

                  <ComponenteInput
                    estado={correo}
                    cambiarEstado={setCorreo}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    label="Correo Electrónico"
                    padLabel="5px 0"
                    onTextlimitChange
                    hLabel=" "
                    border="none"
                    clrLabel="#fff"
                    fontwLabel="400"
                    expresionRegular={expresiones.correo}
                    leyendaError="Correo inválido"
                  />

                  <ComponenteInput
                    estado={curp}
                    cambiarEstado={setCurp}
                    tipo="text"
                    placeholder=""
                    name="curp"
                    label="CURP"
                    padLabel="5px 0"
                    onCurpValidation
                    border="none"
                    hLabel=" "
                    clrLabel="#fff"
                    fontwLabel="400"
                    expresionRegular={expresiones.curp}
                    leyendaError="CURP inválida"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />

                  <ComponenteInput
                    cambiarEstado={setIngresoMensual}
                    estado={ingresoMensual}
                    placeholder=""
                    label="Ingreso mensual"
                    border="none"
                    padLabel="5px 0"
                    hLabel=" "
                    onTextlimitChange
                    clrLabel="#fff"
                    fontwLabel="400"
                    funcion={() =>
                      validarDineroMin(
                        ingresoMensual,
                        setIngresoMensual,
                        1000,
                        9
                      )
                    }
                    leyendaError="Requiere un mínimo de $1,000"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    name="ingreso-mensual"
                  />
                </div>
              </Formulario>

              <Button
                clr="#0061A0"
                bgck="#fff"
                w="119px"
                minWidth="0px"
                ml="150px"
                h="32px"
                pad="6.5px 24px"
                wMob="100%"
                mt="2rem"
                mMob="50px 0px 0px 0px "
                fs="16px"
                disabled={double}
                onClick={(e) => {
                  handleClick();
                  setDouble(true);
                }}
              >
                Continuar
              </Button>
            </FormContainer>
          </ContainerItems>

          <Hidden mdDown>
            <AnimationBox animation={animation} />
          </Hidden>
          {open ? <ModalMessage /> : null}
        </ContainerItems>
      </ContainerItems>
    </>
  );
};

export const CardThree = ({ desiredAmount, balance, voluntaryBalance }) => {
  const navigate = useNavigate();
  return (
    <>
      <ContainerItems fdir="column" m="100px 0 0 0" h=" " mM="64px 0 0 0">
        <TextCarousel m="0px 0px 33px 0px" mbM="20px">
          ¡Excelente!
        </TextCarousel>
        <TextCarouselTwo w="1016px" mM="1rem auto 1rem" wM="95%" fsM="20px">
          De esta forma puedes lograr tus objetivos de ahorro para tu vejez ,
          permítenos darte más información y llevarte de la mano.
        </TextCarouselTwo>

        <ContainerItems
          m="0px 32px 55px 0px"
          fdir="row"
          disMob="column"
          gapM="2rem"
          mM="2rem 0rem 2.5rem"
        >
          <CardCarouselItem height="185px" hM="111px" gapM="0rem" jcM="center">
            <div style={{ verticalAlign: "center" }}>
              <Hidden mdDown>
                <div
                  style={{ height: "90px" }}
                  className="d-flex align-items-center"
                >
                  <TextItem mBottom="0px" width="100%" fsM="18px" c="#C5C6C5">
                    ¡Me gustaría tener mensualmente!
                  </TextItem>
                </div>
              </Hidden>
              <Hidden mdUp>
                <div
                  style={{ height: "auto" }}
                  className="d-flex align-items-center"
                >
                  <TextItem
                    mBottom="0px"
                    width="100%"
                    fsM="18px"
                    lhM="auto"
                    ffM="eliotpro"
                    c="#C5C6C5"
                  >
                    ¡Me gustaría tener mensualmente!
                  </TextItem>
                </div>
              </Hidden>
            </div>
            <TextCantidad fsM="22px" c="#E8E8E8">
              {new Intl.NumberFormat("ES-MX", {
                style: "currency",
                currency: "MXN",
                maximumFractionDigits: 0,
              }).format(desiredAmount)}
            </TextCantidad>
          </CardCarouselItem>
          <CardCarouselItem height="185px" hM="111px" gapM="0rem" jcM="center">
            <div style={{ verticalAlign: "center" }}>
              <Hidden mdDown>
                <div
                  style={{ height: "90px" }}
                  className="d-flex align-items-center"
                >
                  <TextItem mBottom="0px" width="100%" fsM="18px" c="#C5C6C5">
                    {" "}
                    Tendrías que aportar mensualmente:{" "}
                  </TextItem>
                </div>
              </Hidden>
              <Hidden mdUp>
                <div
                  style={{ height: "auto" }}
                  className="d-flex align-items-center"
                >
                  <TextItem
                    mBottom="0px"
                    width="100%"
                    fsM="18px"
                    lhM="auto"
                    ffM="eliotpro"
                    c="#C5C6C5"
                  >
                    {" "}
                    Tendrías que aportar mensualmente:{" "}
                  </TextItem>
                </div>
              </Hidden>
            </div>
            <TextCantidad fsM="22px" c="#E8E8E8">
              {new Intl.NumberFormat("ES-MX", {
                style: "currency",
                currency: "MXN",
                maximumFractionDigits: 0,
              }).format(voluntaryBalance)}
            </TextCantidad>
          </CardCarouselItem>
          <CardCarouselItem height="185px" hM="132px" gapM="0rem" jcM="center">
            <div style={{ verticalAlign: "center" }}>
              <Hidden mdDown>
                <div
                  style={{ height: "90px" }}
                  className="d-flex align-items-center"
                >
                  <TextItem mBottom="0px" width="100%" fsM="18px" c="#C5C6C5">
                    {" "}
                    ¡Podrías tener en tu Afore a los 60 años!{" "}
                  </TextItem>
                </div>
              </Hidden>
              <Hidden mdUp>
                <div
                  style={{ height: "auto" }}
                  className="d-flex align-items-center"
                >
                  <TextItem
                    mBottom="0px"
                    width="100%"
                    fsM="18px"
                    lhM="auto"
                    ffM="eliotpro"
                    c="#C5C6C5"
                  >
                    {" "}
                    ¡Podrías tener en tu Afore a los 60 años!{" "}
                  </TextItem>
                </div>
              </Hidden>
            </div>
            <TextCantidad fsM="22px" c="#E8E8E8">
              {new Intl.NumberFormat("ES-MX", {
                style: "currency",
                currency: "MXN",
                maximumFractionDigits: 0,
              }).format(balance)}
            </TextCantidad>
          </CardCarouselItem>
        </ContainerItems>

        <ContainerItems gap="2rem" disMob="column">
          <ContainerBoxCard
            onClick={(e) => {
              e.preventDefault();
              navigate("/simulador");
            }}
            width="37%"
            widthM="90%"
            mLeft="0%"
            h="211px"
          >
            <ContainerBoxLeft>
              <Hidden mdDown>
                <img src={Monedas2} alt="Moneda" width="45rem" height="45rem" />
              </Hidden>
            </ContainerBoxLeft>
            <ContainerCtaBox>
              <Paragraph30>Ir al simulador</Paragraph30>
              <div style={{ display: "flex" }}>
                <TextSmall
                  width="300px"
                  mTop="3%"
                  widthM="81.5%"
                  fz="18px"
                  ta="start"
                  ptM="0px"
                  h="150px"
                  fzM="14px"
                  mMr="10px"
                  mr="13px"
                >
                  Ya tienes en mente cuanto te gustaría tener el día que te
                  retires. <br />
                  Prueba nuestro simulador para saber que tan cerca te
                  encuentras de ese objetivo.
                </TextSmall>
                <div style={{ marginTop: "35px" }}>
                  <img
                    src="https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/Rowr.62d1c44661879e263222bcf4df9587cb.svg"
                    alt="row"
                  />
                </div>
              </div>
            </ContainerCtaBox>
          </ContainerBoxCard>
        </ContainerItems>
      </ContainerItems>
    </>
  );
};
