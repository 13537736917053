import React, { useState } from "react";

import { CardOne, CardTwo, CardThree } from "../components/CardsCarousel";
import {
  ContainerItems,
  ContainerSection,
} from "../../assets/styles/StylesGeneric.styled";
import { styled } from "@mui/material/styles";
import { Button, Step, StepLabel, Stepper } from "@mui/material";
import buttonIzq from "../../assets/icons/button.svg";
import buttonDer from "../../assets/icons/button_d.svg";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import ClipLoader from "react-spinners/ClipLoader";
import moneda from "../../assets/images/gif/Moneda.gif";
import coins from "../../assets/images/gif/2-Varias-monedas.gif";
import backnotes from "../../assets/images/gif/3-Billetes.gif";
import lambdas from "../../config/lambdas/lambdas";
import moneyBag from "../../assets/images/gif/4-Bolsa.gif";
// import PropTypes from 'prop-types'
const steps = ["", "", ""];

const CustomedConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
    left: "calc(-50% + 15px)",
    right: "calc(50% + 15px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#ffffff",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#ffffff",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  alignItems: "center",
  ...(ownerState.active && {
    color: "#000000",
    width: 32,
    height: 32,
    borderRadius: "50%",
    border: "1px solid #fff",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#000000",
    width: 32,
    height: 32,
    borderRadius: "50%",
    border: "1px solid #fff",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff !important",
  },
  "& .QontoStepIcon-circle": {
    width: 32,
    height: 32,
    borderRadius: "50%",
    border: "2px solid #fff",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className, index } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-completedIcon">{index + 1}</div>
      ) : (
        <div className="QontoStepIcon-circle">{index + 1}</div>
      )}
    </QontoStepIconRoot>
  );
}

const SectionFour = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [desiredAmount, setDesiredAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [voluntaryBalance, setVoluntaryBalance] = useState(0);
  const [formPersonalInfo, setFormPersonalInfo] = useState({
    edad: "",
    telefono: "",
    correo: "",
    curp: "",
    ingreso_mensual: "",
  });
  const [formFilled, setFormFilled] = useState(false);
  const [animation, setAnimation] = useState(moneda);
  const [isLoading, setIsLoading] = useState(false);

  const handleNext = () => {
    if (formFilled || activeStep !== 1) {
      if (activeStep === 1) {
        setIsLoading(true);
        let data = {
          edad: formPersonalInfo.edad,
          edad_retiro: "60",
          ingreso_mensual: formPersonalInfo.ingreso_mensual,
          telefono: formPersonalInfo.telefono,
          me_gustaria_tener: desiredAmount,
          ahorro_afore: "0",
          id_session: sessionStorage.getItem("uuid_principal_user"),
        };
        // const token = lambdas
        //   .getAuth()
        //   .then((response) => {
        //     lambdas
        //       .miniSimuladorAfore(data, response.data.token)
        //       .then((response) => {
        //         setIsLoading(false);
        //         console.log("response", response);
        //         if (!response.data.Error) {
        //           setBalance(response.data.Message.balance);
        //           setVoluntaryBalance(response.data.Message.aporte_mensual);
        //           if (activeStep < 2)
        //             setActiveStep((currenStep) => currenStep + 1);
        //         } else {
        //           alert("Ha ocurrio un error. Contactar al equipo de soporte.");
        //           setIsLoading(false);
        //         }
        //       })
        //       .catch((error) => {
        //         console.log(error.data);
        //       });
        //   })
        //   .catch((error) => {
        //     console.log(error.data);
        //   });
        /**
         * autenticación desde el back
         */
        const token = sessionStorage.getItem("r-token") || "";
        lambdas
          .miniSimuladorAfore(data, token)
          .then((response) => {
            setIsLoading(false);
            console.log("response", response);
            if (!response.data.Error) {
              setBalance(response.data.Message.balance);
              setVoluntaryBalance(response.data.Message.aporte_mensual);
              if (activeStep < 2) setActiveStep((currenStep) => currenStep + 1);
            } else {
              alert("Ha ocurrio un error. Contactar al equipo de soporte.");
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.log(error.data);
          });
      } else {
        if (activeStep < 2) setActiveStep((currenStep) => currenStep + 1);
      }
    } else {
      alert("Completa el formulario para poder continuar.");
    }
  };

  const handleBack = () => {
    setActiveStep((currenStep) => currenStep - 1);
  };
  return (
    <ContainerSection
      mh={isLoading ? "33rem" : activeStep === 0 ? "47rem" : "52rem"}
      mhM="730px"
      h800={activeStep === 2 ? "1000px" : "870px"}
      className="Gradiante-fourSectionLp"
    >
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100 py-5">
          <ClipLoader
            color="#FFFFFF"
            loading={isLoading}
            size={100}
            className="my-5"
          />
        </div>
      ) : (
        <>
          {activeStep === 0 && (
            <CardOne
              handleClick={handleNext}
              onChangeAnimation={setAnimation}
              setValue={setDesiredAmount}
            />
          )}
          {activeStep === 1 && (
            <CardTwo
              onChange={setFormFilled}
              animation={animation}
              setFormData={setFormPersonalInfo}
              handleClick={handleNext}
            />
          )}
          {activeStep === 2 && (
            <CardThree
              desiredAmount={desiredAmount}
              balance={balance}
              voluntaryBalance={voluntaryBalance}
            />
          )}
        </>
      )}

      {/* <InputTest/> */}
      <ContainerItems
        gap="1rem"
        m="50px"
        mM="2rem 0rem 1rem"
        mbM="1rem"
        wM="100%"
        pM="0rem 0.5rem"
      >
        {/* {activeStep !== 0 ? (
          <Button
            onClick={handleBack}
            sx={{
              opacity: "1",
              "@media (max-width: 800px)": {
                width: "48px",
                height: "48px",
                "& img": { width: "40px", height: "40px" },
              },
            }}
          >
            <img src={buttonIzq} alt="" className="imagen" />
          </Button>
        ) : (
          <Button
            onClick={handleBack}
            disabled
            sx={{
              opacity: "0",
              "@media (max-width: 800px)": {
                width: "48px",
                height: "48px",
                "& img": { width: "40px", height: "40px" },
              },
            }}
          >
            <img src={buttonIzq} alt="" className="imagen" />
          </Button>
        )} */}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ "@media (max-width: 800px)": { width: "100%" }, width: "75%" }}
          connector={<CustomedConnector />}
        >
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <QontoStepIcon index={index} {...props} />
                  )}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {/* {activeStep !== 2 ? (
          <Button
            onClick={handleNext}
            disabled={!(activeStep !== steps.length - 1)}
            sx={{
              opacity: "1",
              "@media (max-width: 800px)": {
                width: "48px",
                height: "48px",
                "& img": { width: "40px", height: "40px" },
              },
            }}
          >
            <img src={buttonDer} alt="" />
          </Button>
        ) : (
          <Button
            onClick={handleNext}
            disabled
            sx={{
              opacity: "0",
              "@media (max-width: 800px)": {
                width: "48px",
                height: "48px",
                "& img": { width: "40px", height: "40px" },
              },
            }}
          >
            <img src={buttonDer} alt="" />
          </Button>
        )} */}
      </ContainerItems>
    </ContainerSection>
  );
};

// SectionFour.propTypes = {}

export default SectionFour;
